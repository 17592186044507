<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';

import TabButton from '@/components/ui/tabs/TabButton.vue';
import ProgressStatusLabel from '@/components/features/progress/ProgressStatusLabel.vue';
import EmptyContent from '@/components/ui/empty/EmptyContent.vue';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import FailedApplicantsModal from '@/components/features/autoActions/modals/FailedApplicantsModal.vue';
import autoActionsService from '@/services/autoActions.js';

import { ACTION_TYPE_LABELS } from '@/defines/autoActions';

const MAX_AUTO_ACTIONS_COUNT = 50;

const router = useRouter();
const store = useStore();
const allowCreateAutoAction =
  store.getters['staff/isOwner'] && !store.getters['staff/staff'].is_master;

const autoActions = ref([]);
const isLoading = ref(false);
const isViewFiledApplicantsModal = ref(false);
const selectedActionId = ref(null);

const fetchAutoActions = async () => {
  isLoading.value = true;
  const { data } = await autoActionsService.fetchAutoActions({
    graduated_id: store.getters['graduateds/selectedGraduatedId'],
  });
  autoActions.value = data.actions;
  isLoading.value = false;
};

onMounted(() => {
  fetchAutoActions();
});
const handleClickLogButton = (event, actionId) => {
  event.stopPropagation();
  selectedActionId.value = actionId;
  isViewFiledApplicantsModal.value = true;
};
</script>

<template>
  <div class="page-wrapper">
    <section class="page-header">
      <div class="tabs-wrapper">
        <tab-button :is-active="true">自動アクション一覧</tab-button>
        <tab-button @click="router.push({ name: 'AutoActionsLogs' })">
          自動アクションの履歴
        </tab-button>
      </div>
    </section>
    <section class="page-body">
      <div class="page-body-header">
        <h1 class="page-body-title">自動アクション一覧</h1>
        <div v-if="allowCreateAutoAction" class="block-buttons">
          <div class="button-wrapper">
            <button-base
              type="button"
              class="create-action-button is-small"
              :class="{
                'is-disabled': autoActions.length >= MAX_AUTO_ACTIONS_COUNT,
              }"
              :button-text="'新しい自動アクションを登録'"
              :button-type="'primary'"
              :icon-file-name="'plus_circle'"
              @click="router.push({ name: 'AutoActionsCreate' })"
            />
          </div>
          <div class="button-wrapper">
            <button-base
              type="button"
              class="create-action-button is-small"
              :class="{
                'is-disabled': autoActions.length >= MAX_AUTO_ACTIONS_COUNT,
              }"
              :button-text="'既存の自動アクションから複製'"
              :button-type="'primary'"
              :icon-file-name="'plus_circle'"
              @click="router.push({ name: 'AutoActionsCopy' })"
            />
            <span class="caption">
              ※自動アクションを実行する選考は事前に登録が必要になります
            </span>
          </div>
        </div>
        <small
          v-if="
            allowCreateAutoAction &&
            autoActions.length >= MAX_AUTO_ACTIONS_COUNT
          "
          class="caption"
        >
          ※ひとつの卒業年数で登録できる自動アクションの上限は50件までです
        </small>
      </div>

      <div v-if="isLoading">
        <local-page-loader />
      </div>
      <div v-else-if="autoActions.length === 0">
        <empty-content>登録済みの自動アクションはありません</empty-content>
      </div>
      <div v-else class="table-wrapper">
        <table class="table">
          <thead>
            <th class="fit-left">
              <span>有効 / 無効</span>
            </th>
            <th class="fit-left">
              <span>最終実行日時</span>
            </th>
            <th><span>自動アクション名</span></th>
            <th><span>対象の選考</span></th>
            <th><span>対象のステータス</span></th>
            <th><span>アクション</span></th>
            <th class="fit-left">
              <span>フラグ指定</span>
            </th>
            <th class="fit-right"><span></span></th>
          </thead>
          <tbody>
            <tr
              v-for="action in autoActions"
              :key="action.id"
              @click="
                router.push({
                  name: 'AutoActionsDetail',
                  params: { id: action.id },
                })
              "
            >
              <td class="fit-left">
                {{ action.status ? '有効' : '無効' }}
              </td>
              <td class="fit-left">
                {{
                  action.last_executed_at
                    ? moment(action.last_executed_at).format(
                        'YYYY年MM月DD日 HH:mm',
                      )
                    : '-'
                }}
              </td>
              <td class="text-ellipsis">
                {{ action.action_name }}
              </td>
              <td class="text-ellipsis">
                {{ action.trigger_conditions.event_name }}
              </td>
              <td>
                <div class="progress-statuses">
                  <progress-status-label
                    v-for="progress in [
                      ...action.trigger_conditions.progress_status_ids,
                      ...action.trigger_conditions.finished_progress_status_ids,
                    ]"
                    :key="progress.name"
                    :label-name="progress.name"
                    class="w-fit sm"
                  />
                </div>
              </td>
              <td>
                {{ ACTION_TYPE_LABELS[action.action_type] }}
              </td>
              <td class="fit-left">
                {{ action.trigger_conditions.has_flag ? 'あり' : 'なし' }}
              </td>
              <td class="fit-right button-item">
                <button-base
                  type="button"
                  class="log-button is-small"
                  :class="{ 'is-disabled': !action.exist_failed }"
                  :is-loading="isLoading"
                  :button-text="'失敗ログ'"
                  :button-type="'primary'"
                  @click="handleClickLogButton($event, action.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <failed-applicants-modal
        :is-view="isViewFiledApplicantsModal"
        :action-id="selectedActionId"
        @close="isViewFiledApplicantsModal = false"
        @update-auto-actions="fetchAutoActions"
      />
    </section>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include tabsWrapper;
@include tableStyle;

.page-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.page-header {
  padding: 0 0 32px;
  border-bottom: 1px solid $mochica_color_gray2;
}
.page-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.page-body-header {
  display: flex;
  flex-direction: column;
  .btn-base {
    width: fit-content;
  }
}
.page-body-title {
  margin-bottom: 20px;
  font-size: $font_20;
  font-weight: bold;
}
.create-action-button {
  margin-bottom: 12px;
}
.progress-statuses {
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
  .progress-status-label {
    flex-basis: 100%;
  }
  .w-fit {
    width: fit-content;
    padding: 8px 16px;
  }
}
.caption {
  font-size: $font_12;
  color: $system_color;
}
.log-button,
.create-action-button {
  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.block-buttons {
  display: flex;
  gap: 28px;
  .caption {
    font-size: $font_14;
  }
}
.button-wrapper {
  display: flex;
  flex-direction: column;
  & + & {
    border-left: 1px solid $mochica_color_gray2;
    padding-left: 28px;
  }
}
</style>
