<template>
  <section class="si-block" :class="{ edit: isEditSelectionMode === true }">
    <div class="headline">
      <img class="icon" height="22" src="@/assets/img/selection.svg" alt />
      選考の基本情報
    </div>
    <div class="inner">
      <div class="edit-block">
        <p class="title">選考名</p>
        <div
          v-if="
            (isEditSelectionMode === true && selection.count === 0) ||
            isRegistrationSelection === true
          "
          class="pos-relative"
        >
          <input
            class="under-bar-hi-light"
            :class="{ 'is-danger': errors.eventTitle.isValid !== true }"
            type="text"
            maxlength="20"
            :value="selectedEventTitle"
            @click="isEditEventMode = true"
          />
          <popup-select-event
            v-if="isEditEventMode === true"
            ref="refSelectEventPopup"
            :list="events.map(v => ({ id: v.id, name: v.title }))"
            :select-id="selection.event.id"
            @onClickNewItem="onClickNewItemEvent"
            @onClickItem="onClickSelectEvent"
            @close="isEditEventMode = false"
          />
        </div>
        <div v-else class="value">{{ selectedEventTitle }}</div>
        <span
          v-if="errors.eventTitle.isValid !== true"
          class="fa-warning font-s error_message"
        >
          {{ errors.eventTitle.message }}
        </span>
      </div>
      <div class="edit-block">
        <p class="title">管理用メモ</p>
        <div v-if="isEditSelectionMode === true">
          <textarea
            v-model="selection.description"
            placeholder="この選考の概略説明の記載に自由にご使用ください。※この連絡事項は応募者に通知されません（文字数は30文字までです）。"
            maxlength="30"
            class="area-notice-txt"
          />
        </div>
        <div v-else>
          <textarea
            class="comment_area"
            readonly
            :value="selection.description"
          />
        </div>
      </div>
      <div class="edit-block">
        <p class="title">
          応募者向け連絡事項
          <span v-if="selection.updated_at !== null">
            <br class="-spView" />
            （最終更新日：{{
              $utils.parseDateTime(
                selection.updated_at,
                'YYYY年MM月DD日 HH:mm',
              )
            }}）
          </span>
        </p>
        <div
          v-if="
            isEditSelectionMode === true || isRegistrationSelection === true
          "
        >
          <textarea
            v-model="selection.notice"
            placeholder="当日は履歴書と筆記用具を持参してください。※この連絡事項は応募者に通知されます（文字数は500文字までです）。"
            maxlength="500"
            class="area-notice-txt for-applicant"
            :class="{ 'is-danger': errors.notice.isValid !== true }"
            @input="
              validateOnInput('notice', selection.notice, validateRequire)
            "
          />
          <span
            v-if="errors.notice.isValid !== true"
            class="fa-warning font-s error_message"
          >
            {{ errors.notice.message }}
          </span>
        </div>
        <div v-else>
          <textarea
            class="comment_area for-applicant"
            readonly
            :value="selection.notice"
          />
        </div>
      </div>
      <div class="edit-block">
        <div
          v-if="
            isEditSelectionMode === true || isRegistrationSelection === true
          "
        >
          <label for="reserve" class="checkbox-label">
            <input
              id="reserve"
              v-model="selection.reserve"
              name="reserve"
              type="checkbox"
              class="setting_input"
              :checked="selection.reserve"
              @input="selection.reserve = !selection.reserve"
            />
            選考予約一覧に表示させる
          </label>
          <label for="reminder_check" class="checkbox-label">
            <input
              id="reminder_check"
              v-model="selection.reminder_check"
              name="reminder_check"
              type="checkbox"
              class="setting_input"
              :checked="selection.reminder_check"
              @input="selection.reminder_check = !selection.reminder_check"
            />
            選考担当者に前日リマインドメールを通知する
          </label>
        </div>
        <div v-else>
          <label class="checkbox-label is-disabled">
            <input
              type="checkbox"
              :disabled="true"
              class="setting_input"
              :checked="selection.reserve"
            />
            選考予約一覧に表示させる
          </label>
          <label class="checkbox-label is-disabled">
            <input
              type="checkbox"
              :disabled="true"
              class="setting_input"
              :checked="selection.reminder_check"
            />
            選考担当者に前日リマインドメールを通知する
          </label>
        </div>
      </div>
      <div
        v-if="
          isEditSelection === true &&
          $store.getters['staff/isOperator'] !== true
        "
        class="btns-area"
      >
        <button-base
          v-if="isEditSelectionMode === true"
          button-text="選考の基本情報を確定"
          button-type="success"
          icon-file-name="check"
          :is-loading="isLoading === true"
          :rejected-role-ids="rejectedRoleIds"
          :rejected-admin-ids="rejectedAdminIds"
          @click="onClickUpdateSelection"
        />
        <button-base
          v-else
          button-text="選考の基本情報を編集"
          button-type="primary"
          icon-file-name="pen"
          :is-loading="isLoading === true"
          :rejected-role-ids="rejectedRoleIds"
          :rejected-admin-ids="rejectedAdminIds"
          @click="onClickEditSelectionInfo"
        />
        <button-base
          v-if="isDeletableSelection === true"
          class="btns"
          :button-text="'この選考を削除'"
          :button-type="'secondary'"
          :icon-file-name="'trash'"
          :rejected-role-ids="rejectedRoleIds"
          :rejected-admin-ids="rejectedAdminIds"
          @click="onClickDeleteSelection"
        />
      </div>
    </div>
    <edit-disabled-modal
      :is-view="isViewEditDisabledModal"
      :actions="actions"
      :target-name="'選考'"
      :action-type="'自動アクションさせる選考'"
      :operation-name="'削除'"
      @close="isViewEditDisabledModal = false"
    />
  </section>
</template>

<script>
import { computed, defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import useValidation from '@/composables/useValidation';
import PopupSelectEvent from '@/components/features/selectionDetailBody/components/PopupSelectEvent';
import EditDisabledModal from '@/components/features/autoActions/modals/EditDisabledModal';

export default defineComponent({
  name: 'SelectionDetailBodyBasicInfo',
  components: { PopupSelectEvent, EditDisabledModal },
  props: {
    graduatedId: {
      type: Number,
      default: null,
    },
    selection: {
      type: Object,
      default: () => {},
    },
    events: {
      type: Array,
      default: () => [],
    },
    selectionVenues: {
      type: Array,
      default: () => [],
    },
    isRegistrationSelection: {
      type: Boolean,
      default: false,
    },
    isEditSelection: {
      type: Boolean,
      default: false,
    },
    isConnectedGoogleCalendar: {
      type: Boolean,
      default: false,
    },
    createEvent: {
      type: Function,
      default: () => {},
    },
    updateSelectionBasic: {
      type: Function,
      default: () => {},
    },
    updateSelectionEvent: {
      type: Function,
      default: () => {},
    },
    updateSelectionBasicInRegistration: {
      type: Function,
      default: () => {},
    },
    deleteSelection: {
      type: Function,
      default: () => {},
    },
    quotationSelection: {
      type: Object,
      default: null,
    },
  },
  emits: ['fetchAll'],
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const { errors, resetErrors, validateOnInput, validateRequire } =
      useValidation(['eventTitle', 'notice']);
    const refSelectEventPopup = ref(null);
    const isLoading = ref(false);
    // 選考作成時のみ編集状態が初期状態
    const isEditSelectionMode = ref(props.isRegistrationSelection);
    const isEditEventMode = ref(false);
    const storedNotice = ref(props.selection.notice);

    const isViewEditDisabledModal = ref(false);
    const actions = ref([]);

    // computed
    const selectedEventTitle = computed(() => {
      if (!props.selection.event.id) return '';
      if (props.events.find(v => v.id === props.selection.event.id)) {
        return props.events.find(v => v.id === props.selection.event.id).title;
      }
      return '';
    });
    // 選考削除条件
    const isDeletableSelection = computed(() => {
      // 会場or開催時間が2個以上の場合、削除できない
      if (
        props.selectionVenues.length > 1 ||
        props.selectionVenues[0].timetables.length > 1
      ) {
        return false;
      }
      // Google連携済みかつ社外スタッフの場合、削除できない
      if (
        props.isConnectedGoogleCalendar === true &&
        props.isGoogleMember === false
      ) {
        return false;
      }
      return true;
    });
    const isGoogleMember = computed(() => {
      return store.getters['staff/staff']
        ? store.getters['staff/staff'].is_google_member
        : false;
    });

    // 選考の確定押下イベント
    const onClickUpdateSelection = async () => {
      if (validateBasicInfo() !== true) return;
      isEditSelectionMode.value = false;
      const tmpSelection = {
        eventId: props.selection.event.id,
        notice: props.selection.notice,
        description: props.selection.description,
        reserve: props.selection.reserve,
        // 連絡事項の文言を更新した場合のみアップデートフラグtrue
        updateFlag: props.selection.notice !== storedNotice.value,
        reminderCheck: props.selection.reminder_check,
      };
      if (props.isRegistrationSelection === true) {
        // 新規登録時
        props.updateSelectionBasicInRegistration(tmpSelection);
        return;
      }
      if (
        props.isEditSelection === true &&
        props.selection.notice.notice !== ''
      ) {
        isLoading.value = true;
        // 選考編集時
        const res = await props.updateSelectionBasic(tmpSelection);
        if (res.success === true) {
          storedNotice.value = props.selection.notice;
          context.emit('fetchAll');
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: '選考の基本情報を変更しました。',
            type: res.success,
          });
        }
        isLoading.value = false;
      }
    };
    // 基本情報を編集押下イベント
    const onClickEditSelectionInfo = () => {
      if (isEditSelectionMode.value === true && props.selection !== '') return;
      // 編集前画面であれば編集画面に変更
      isEditSelectionMode.value = true;
    };
    // イベント新規作成クリックイベント
    const onClickNewItemEvent = async ev => {
      const countArr = Object.entries(props.events).filter(
        v => v[1].title === ev.name,
      );
      const deleteSpaceStr = ev.name.replace(/[\t\s ]/g, '');
      if (countArr && countArr.length > 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'すでに同じ選考名が登録されています',
          type: false,
        });
        refSelectEventPopup.value.reset();
        return;
      }
      if (deleteSpaceStr.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: 'スペースは登録できません',
          type: false,
        });
        refSelectEventPopup.value.reset();
        return;
      }
      if (deleteSpaceStr.length > 20) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '選考名は20文字以下で登録してください',
          type: false,
        });
        refSelectEventPopup.value.reset();
        return;
      }
      // eventの新規作成
      await props.createEvent(ev.name, props.graduatedId);
      // 選考選択ポップアップのリセット
      refSelectEventPopup.value.reset();
    };
    // 選考削除クリックイベント
    const onClickDeleteSelection = () => {
      store.dispatch('ui/modalDialog/VISIBLE_DIALOG', {
        accentColor: '#1899D6',
        title: 'この選考を削除します',
        message:
          '削除された選考は復元できません。この選考を削除してよろしいですか？',
        buttonText1: 'キャンセル',
        buttonText2: 'はい',
        onSelected: async ({ choice }) => {
          if (choice !== 2) {
            store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
            return;
          }
          const res = await props.deleteSelection(store);
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
          if (!res.success && res?.actions?.length > 0) {
            actions.value = res.actions;
            isViewEditDisabledModal.value = true;
            return;
          }
          if (res.success !== true) return;
          router.replace('/selections');
        },
        onclickOuter: () => {
          store.dispatch('ui/modalDialog/INVISIBLE_DIALOG');
        },
        isDisabledShowModalDisplayed: true,
      });
    };
    // イベント選択クリックイベント
    const onClickSelectEvent = ev => {
      props.updateSelectionEvent(ev);
      validateRequire('eventTitle', selectedEventTitle.value) === true;
      isEditEventMode.value = false;
    };
    // 選考基本情報のバリデーション
    const validateBasicInfo = () => {
      // 親コンポーネントで呼び出されているので注意
      resetErrors();
      const isValidTitle = validateRequire(
        'eventTitle',
        selectedEventTitle.value,
      );
      const isValidNotice = validateRequire('notice', props.selection.notice);
      return isValidTitle === true && isValidNotice === true;
    };

    const initializeQuotationSelection = async () => {
      try {
        if (!props.quotationSelection) return;

        props.selection.notice = props.quotationSelection.notice;
        props.selection.description = props.quotationSelection.description;
        const filteredEvents = props.events.find(
          v => v.title === props.quotationSelection.eventTitle,
        );

        if (filteredEvents) {
          props.selection.event.id = filteredEvents.id;
        } else {
          await props.createEvent(
            props.quotationSelection.eventTitle,
            props.graduatedId,
          );
        }
      } catch (error) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '選考データの取得に失敗しました',
          type: false,
        });
      }
    };

    onMounted(async () => {
      await initializeQuotationSelection();
    });

    return {
      errors,
      rejectedRoleIds: [3],
      rejectedAdminIds: [2],
      refSelectEventPopup,
      isLoading,
      isEditSelectionMode,
      isEditEventMode,
      selectedEventTitle,
      isDeletableSelection,
      isGoogleMember,
      isViewEditDisabledModal,
      actions,
      validateOnInput,
      validateRequire,
      onClickSelectEvent,
      onClickUpdateSelection,
      onClickEditSelectionInfo,
      onClickNewItemEvent,
      onClickDeleteSelection,
      validateBasicInfo,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects3();

.si-block {
  margin-bottom: 30px;
  background-color: #fff;
  > .headline {
    padding: 10px 20px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: #4397d1;
  }
  > .headline > .icon {
    margin-right: 10px;
  }
  .inner {
    padding: 30px;
  }
}

.edit-block {
  + .edit-block {
    margin-top: 30px;
  }
  .title {
    margin-bottom: 5px;
    line-height: 1.4;
    font-size: 1.5rem;
  }
  .value {
    padding-bottom: 10px;
    border-bottom: 1px solid #e3e3e3;
    line-height: 1.2;
    font-size: 1.3rem;
  }
  .comment_area {
    padding: 7px;
    border-radius: 6px;
    border: 1px solid #e3e3e3;
    line-height: 1.3;
    font-size: 1.4rem;
    &.for-applicant {
      resize: vertical;
    }
  }
}

.checkbox-label {
  display: block;
  cursor: pointer;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  &.is-disabled {
    cursor: default;
  }
  > .setting_input {
    appearance: none;
    position: relative;
    width: 17px;
    height: 17px;
    margin-right: 3px;
    vertical-align: -3px;
    border-radius: 4px;
    border: 2px solid #9d9d9d !important;
    background: #fff;
    &:checked {
      border: 2px solid #9d9d9d !important;
      &::after {
        position: absolute;
        left: 0;
        bottom: -1px;
        content: url(../../../../assets/img/check.svg);
        width: 18px;
        height: auto;
      }
    }
    &:disabled {
      cursor: default;
    }
  }
}

.list-applicant-title {
  width: 100%;
  padding: 10px;
  line-height: 1.4;
  color: #1899d6;
  font-weight: bold;
  background-color: #e9f3f8;
}

.under-bar-hi-light {
  &.txt-warning {
    margin-bottom: 10px;
    border: 1px solid #c31e1e;
  }
}

.area-notice-txt {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #adadad;
  line-height: 1.3;
  font-size: 1.4rem;
  &.txt-warning {
    margin-bottom: 10px;
    border: 1px solid #c31e1e;
  }
  &.for-applicant {
    resize: vertical;
  }
}

.btns-area {
  margin-top: 30px;
  text-align: center;

  button {
    margin: 10px 6px 0 0;
  }
}

@media (max-width: ($media_query_sp)) {
  .si-block {
    .inner {
      padding: 20px;
    }
  }
  .btns-area {
    text-align: left;
    margin-top: 20px;
  }
}
</style>
