<template>
  <div class="area-selection">
    <bread-crumb-list>
      <template #breadcrumb>
        <span>選考一覧</span>
      </template>
    </bread-crumb-list>

    <section class="content-warp">
      <div v-if="$store.getters['staff/isOperator'] !== true" class="content">
        <p class="headline-typeC">選考データの登録</p>
        <div class="content-block">
          <div class="content-block-button">
            <button-base
              :button-text="'新しい選考を登録'"
              :icon-file-name="'plus_circle'"
              :rejected-role-ids="[ROLE_IDS.user]"
              :rejected-admin-ids="[ADMIN_IDS.master]"
              @click="
                $router.push('/selections/registration');
                postPottosEvent(15);
              "
            />
          </div>
          <div class="content-block-button">
            <button-base
              :button-text="'既存の選考から登録する'"
              :icon-file-name="'plus_circle'"
              :rejected-role-ids="[ROLE_IDS.user]"
              :rejected-admin-ids="[ADMIN_IDS.master]"
              @click="isVisibleSelectSelectionModal = true"
            />
            <div class="content-block-button-text">
              ※選考名・管理用メモ・応募者向け連絡事項が引用されます。
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <p class="headline-typeC">選考一覧</p>
        <selections-search />
      </div>
    </section>
    <selection-graduated-modal
      :is-visible="isVisibleSelectSelectionModal"
      @close="isVisibleSelectSelectionModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { postPottosEvent } from '@/utils/pottos';

import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import SelectionsSearch from '@/components/features/selectionsSearch/components/SelectionsSearch';
import SelectionGraduatedModal from '@/components/features/selections/SelectionGraduatedModal.vue';
import { ADMIN_IDS } from '@/defines/admin';
import { ROLE_IDS } from '@/defines/role';

export default defineComponent({
  name: 'Selections',
  components: { BreadCrumbList, SelectionsSearch, SelectionGraduatedModal },
  setup(props, context) {
    const isVisibleSelectSelectionModal = ref(false);
    return {
      isVisibleSelectSelectionModal,
      ADMIN_IDS,
      ROLE_IDS,
      postPottosEvent,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.display_none {
  display: none;
}
.content {
  padding: 40px 30px;
  background: #fff;
  + .content {
    margin-top: 20px;
  }
}
.edit-selection {
  margin-left: 7px;
}

.content-block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;

  .content-block-button + .content-block-button {
    position: relative;
    padding-left: 30px;
  }
  .content-block-button + .content-block-button::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: $border_color;
  }
  .content-block-button-text {
    margin-top: 10px;
    font-size: $font_14;
    color: #999;
  }
}

@media (max-width: ($media_query_tablet)) {
  .content {
    padding: 40px;
  }
}
@media (max-width: ($media_query_sp)) {
  .content {
    padding: 10px;
  }
}
</style>
