<template>
  <div
    v-if="
      $store.getters['page/isPageLoaded'] === true &&
      isLocalStateLoaded === true
    "
    ref="refSelectionDetailBody"
  >
    <selection-detail-body-basic-info
      ref="refSelectionsBasicInfo"
      :graduated-id="graduatedId"
      :init-notice="selection.data.notice"
      :selection="selection.data"
      :events="events"
      :selection-venues="selectionVenues"
      :is-connected-google-calendar="isConnectedGoogleCalendar"
      :is-registration-selection="isRegistrationSelection"
      :is-edit-selection="isEditSelection"
      :create-event="createEvent"
      :update-selection-basic="updateSelectionBasic"
      :update-selection-event="updateSelectionEvent"
      :update-selection-basic-in-registration="
        updateSelectionBasicInRegistration
      "
      :delete-selection="deleteSelection"
      :quotation-selection="quotationSelection"
      @fetchAll="fetchAll"
    />
    <section v-if="isEditSelection === true" class="si-block">
      <div class="headline">
        <img
          class="icon"
          height="22"
          src="@/assets/img/selection_applicants.svg"
          alt
        />
        選考への応募者を登録
      </div>
      <div class="inner">
        <p class="-mb20">
          現在この選考には以下の {{ applicantSearch.pager.count }}名
          が登録されています。
        </p>
        <div v-if="applicants.length > 0" class="register_applicant_block">
          <selectable-applicant-table
            :applicants="applicants"
            :can-move-detail="true"
            :invisible-columns="invisibleColumns"
            @onChangeSort="onApplicantsChangeSort($event, $store)"
          />
          <pager
            :page="applicantSearch.pager.page"
            :count="applicantSearch.pager.count"
            :page-count="applicantSearch.pager.pageCount"
            :load="10"
            @move="movePager"
          />
        </div>
        <div class="newRegisterBtnArea registerApplicant-btns btns-area">
          <button-base
            :button-text="'応募者を新しく登録'"
            :icon-file-name="'plus_circle'"
            :rejected-admin-ids="rejectedAdminIds"
            @click="isVisibleModalSelectionsApplicant = true"
          />
        </div>
        <div class="btns-area registerApplicant-btns">
          <button-base
            class="btns"
            :button-text="'結果未通知の応募者の選考を進める'"
            :icon-file-name="'arrow_right'"
            :rejected-admin-ids="rejectedAdminIds"
            @click="openModalBulkAnnounceSkip"
          />
          <button-base
            v-if="selection.data.count !== 0"
            class="btns"
            :button-text="'応募者のCSVをダウンロード'"
            :icon-file-name="'download'"
            :rejected-admin-ids="rejectedAdminIds"
            @click="downloadApplicantCsv({ selectionId: id })"
          />
        </div>
        <modal-selections-applicant
          ref="refModalSelectionsApplicant"
          :is-view="isVisibleModalSelectionsApplicant"
          :selection-id="id"
          :selection-name="selection.data.event.title"
          :selection-count="applicantSearch.pager.count"
          @onClickCloseModal="isVisibleModalSelectionsApplicant = false"
          @onClickRegisterApplicant="registerApplicantLocal"
        />
        <modal-bulk-announce-skip
          :is-view="isVisibleModalBulkAnnounceSkip"
          :applicants="applicantsNotAnnounce"
          @onClickCancel="isVisibleModalBulkAnnounceSkip = false"
          @onClickSettle="onClickBulkAnnounceSkip"
        />
      </div>
    </section>
    <section class="si-block">
      <div class="headline">
        <img class="icon" src="@/assets/img/selection.svg" height="22" alt />
        選考「{{ selection.data.event.title }}」の選考会場一覧
      </div>
      <div class="venueList-head">
        <div class="btns-area-venue">
          <button-base
            v-if="
              isEditSelection === true &&
              isModalSelectionMode !== true &&
              $store.getters['staff/isOperator'] !== true
            "
            class="btns"
            button-text="選考会場を追加"
            icon-file-name="plus_circle"
            :rejected-role-ids="rejectedRoleIds"
            :rejected-admin-ids="rejectedAdminIds"
            @click="addLocalVenue"
          />
          <div>
            <p>選考の並び順</p>
            <div
              class="icon-sortable"
              @click="onClickSortDate(sortDate ? 0 : 1, $router)"
            >
              <span slot="text">
                {{ sortDate ? '新しい日付順' : '古い日付順' }}
              </span>
              <img
                slot="arrow"
                :class="
                  sortDate ? 'icon-sortable-svg-up' : 'icon-sortable-svg-down'
                "
                src="@/assets/img/arrow_down_blue.svg"
                alt
              />
            </div>
          </div>
        </div>
      </div>
      <div class="venueList-body">
        <ul class="venue-list">
          <selections-venue
            v-for="(venue, iVenue) in selectionVenues"
            ref="refSelectionsVenue"
            :key="iVenue"
            :max-venue-length="MAX_VENUE_LENGTH"
            :max-timetable-length="MAX_TIMETABLE_LENGTH"
            :i-venue="iVenue"
            :selection-id="id"
            :is-registration-selection="isRegistrationSelection"
            :is-edit-selection="isEditSelection"
            :is-deletable="venue.isDeletable() && selectionVenues.length >= 2"
            :is-modal-selection-mode="isModalSelectionMode"
            :staffs="staffs"
            :google-resources="googleResources"
            :is-connected-google-calendar="isConnectedGoogleCalendar"
            :enable-zoom="enableZoom"
            :zoom-users="zoomUsers"
            :selection-venues="selectionVenues"
            :create-venue="createVenue"
            :update-venue="updateVenue"
            :delete-venue="deleteVenue"
            :update-timetable="updateTimetable"
            :update-venue-data="updateVenueData"
            :update-venue-data-by-key="updateVenueDataByKey"
            :add-timetable-in-venue-data="addTimetableInVenueData"
            :download-applicant-csv="downloadApplicantCsv"
            :delete-timetable="deleteTimetable"
            :update-active-venue="updateActiveVenue"
            :send-assignment-reminder="sendAssignmentReminder"
            :update-timetable-data-by-index-in-venue-data="
              updateTimetableDataByIndexInVenueData
            "
            :delete-zoom-meeting="deleteZoomMeeting"
            :update-google-event="updateGoogleEvent"
            :update-zoom-meeting="updateZoomMeeting"
            :reset-staffs-selected="resetStaffsSelected"
            @onCopyVenue="copyLocalVenue"
            @onAddVenue="onAfterAddedVenue"
            @fetchAll="fetchAll"
            @fetchSelectionDetail="fetchSelectionDetail"
            @selectStaff="selectStaff"
          />
        </ul>
      </div>
      <div class="venueList-foot">
        <div class="btns-area">
          <button-base
            v-if="
              isEditSelection === true &&
              isModalSelectionMode !== true &&
              $store.getters['staff/isOperator'] !== true
            "
            class="btns"
            button-text="選考会場を追加"
            icon-file-name="plus_circle"
            :rejected-role-ids="rejectedRoleIds"
            :rejected-admin-ids="rejectedAdminIds"
            @click="addLocalVenue"
          />
        </div>
        <div class="btns-area-bottom">
          <button-base
            v-if="
              isRegistrationSelection === true &&
              isModalSelectionMode !== true &&
              $store.getters['staff/isOperator'] !== true
            "
            class="registration-button"
            button-text="選考を登録"
            button-type="success"
            icon-file-name="check"
            :is-loading="isLoading"
            :rejected-role-ids="rejectedRoleIds"
            :rejected-admin-ids="rejectedAdminIds"
            @click="cerateSelectionLocal"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  watch,
  nextTick,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import VenueModel from '@/models/venue';
import applicantService from '@/services/applicants';

import Breakpoints from '@/defines/breakpoints';
import { useSelection } from '@/components/features/selectionDetailBody/composables/useSelection';
import Pager from '@/components/ui/pager/components/Pager';
import SelectableApplicantTable from '@/components/features/applicantTable/components/SelectableApplicantTable';
import SelectionDetailBodyBasicInfo from '@/components/features/selectionDetailBody/components/SelectionDetailBodyBasicInfo';
import ModalSelectionsApplicant from '@/components/features/selectionDetailBody/components/ModalSelectionsApplicant';
import ModalBulkAnnounceSkip from '@/components/features/selectionDetailBody/components/ModalBulkAnnounceSkip';
import SelectionsVenue from '@/components/features/selectionDetailBody/components/SelectionsVenue';
import { postPottosEvent } from '@/utils/pottos';

export default defineComponent({
  name: 'SelectionDetailBody',
  components: {
    SelectionDetailBodyBasicInfo,
    SelectionsVenue,
    ModalSelectionsApplicant,
    SelectableApplicantTable,
    Pager,
    ModalBulkAnnounceSkip,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    selectionDetailMode: {
      type: Number,
      default: 1,
    }, // view: 1 | registration: 2 | edit: 3
    isModalSelectionMode: {
      type: Boolean,
      default: false,
    },
    initialDate: {
      type: String,
      default: null,
    },
    initGraduatedId: {
      type: Number,
      default: null,
    },
    quotationSelection: {
      type: Object,
      default: null,
    },
  },
  emits: ['updateSelection'],
  setup(props, context) {
    const {
      MAX_VENUE_LENGTH,
      MAX_ACTIVE_VENUE_LENGTH,
      MAX_TIMETABLE_LENGTH,
      selection,
      events,
      staffs,
      applicants,
      applicantSearch,
      googleResources,
      isConnectedGoogleCalendar,
      enableZoom,
      zoomUsers,
      sortDate,
      selectionVenues,
      isLocalStateLoaded,
      isRegistrationSelection,
      isEditSelection,
      checkLinkGoogleCalendar,
      checkLinkZoom,
      fetchSelectionDetail,
      fetchStaffs,
      fetchEvents,
      fetchApplicants,
      cerateSelection,
      deleteVenue,
      updateActiveVenue,
      onClickSortDate,
      registerApplicant,
      downloadApplicantCsv,
      updateGoogleEvent,
      updateZoomMeeting,
      deleteZoomMeeting,
      resetSelectionLocal,
      addVenueData,
      updateVenueDataByKey,
      selectStaff,
      resetStaffsSelected,
      onApplicantsChangeSort,
      createVenue,
      updateVenue,
      updateTimetable,
      deleteTimetable,
      updateVenueData,
      addTimetableInVenueData,
      sendAssignmentReminder,
      updateTimetableDataByIndexInVenueData,
      createEvent,
      updateSelectionBasic,
      updateSelectionEvent,
      updateSelectionBasicInRegistration,
      deleteSelection,
    } = useSelection({
      initSelectionId: props.id,
      initSelectionDetailMode: props.selectionDetailMode,
    });
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const refSelectionDetailBody = ref(null);
    const refSelectionsBasicInfo = ref(null);
    const refSelectionsVenue = ref(null);
    const refModalSelectionsApplicant = ref(null);
    const invisibleColumns = ref([]);
    const applicantsNotAnnounce = ref([]);
    const bulkAssignTargetVenueId = ref(null);
    const timeoutId = ref(null);
    const isLoading = ref(false);
    const isVisibleModalSelectionsApplicant = ref(false);
    const isVisibleModalBulkAnnounceSkip = ref(false);
    const isVisibleModalBulkAssignmentStaff = ref(false);

    // computed
    const graduatedId = computed(() => {
      if (props.initGraduatedId !== null) return props.initGraduatedId;
      return store.getters['graduateds/selectedGraduatedId'];
    });
    const isGoogleMember = computed(() => {
      return store.getters['staff/staff']
        ? store.getters['staff/staff'].is_google_member
        : false;
    });

    // watch
    watch(
      () => route,
      async () => await fetchAll(),
    );
    // 応募者詳細の選考作成モーダルで親コンポーネントにselectionデータを渡すために利用
    watch(
      () => selection,
      val => context.emit('updateSelection', val.data),
      { deep: true },
    );
    watch(
      () => store.getters['page/isPageLoaded'],
      val => {
        if (!route.hash || val === false) return;
        nextTick(() => {
          // venuesのDOM表示後、URLにhashが存在すれば該当のvenue_idのアンカーまでスクロール
          if (!refSelectionDetailBody.value) return;
          const targetEl = refSelectionDetailBody.value.querySelector(
            `#${route.hash.slice(1)}`,
          );
          if (!targetEl) return;
          window.scrollTo({
            left: 0,
            top: window.pageYOffset + targetEl.getBoundingClientRect().top - 70,
          });
        });
      },
    );

    // methods
    const fetchAll = async () => {
      await fetchStaffs();
      await fetchEvents(graduatedId.value);
      if (isRegistrationSelection.value !== true) {
        // 詳細表示・編集時
        await fetchSelectionDetail(router);
        await fetchApplicants(1, store);
        await store.dispatch('page/SET_LOADED');
        return;
      }
      // 新規登録時
      await checkLinkGoogleCalendar(store);
      await checkLinkZoom(store);
      // 選考新規作成画面での選考初期化
      resetSelectionLocal();
      await store.dispatch('page/SET_LOADED');
    };
    // 結果未通知の応募者の選考を進めるモーダル表示
    const openModalBulkAnnounceSkip = async () => {
      const graduatedYear =
        await store.getters['graduateds/selectedGraduatedYear'];
      const notAnnounceApplicants =
        await applicantService.fetchNotAnnounceApplicants(
          graduatedYear.year,
          props.id,
        );
      applicantsNotAnnounce.value = notAnnounceApplicants;
      isVisibleModalBulkAnnounceSkip.value = true;
    };
    // 結果未通知の応募者の選考を進める処理
    const onClickBulkAnnounceSkip = async payload => {
      const res = await applicantService.announceSkipApplicants(payload);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success !== true) return;
      isVisibleModalBulkAnnounceSkip.value = false;
      await fetchAll();
    };
    // 選考の新規登録
    const cerateSelectionLocal = async () => {
      const isValidSelection = validationSelectionBasicInfo();
      const isValidVenues = validateVenues();
      if (isValidSelection !== true || isValidVenues !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '入力内容を確認してください。',
          type: false,
        });
        return;
      }
      isLoading.value = true;
      await cerateSelection(store, router);
      isLoading.value = false;
      postPottosEvent(16);
    };
    // 選考会場追加後の処理
    const onAfterAddedVenue = async () => {
      await fetchAll();
      if (
        selectionVenues.value.filter(v => v.active).length <=
        MAX_ACTIVE_VENUE_LENGTH
      ) {
        return;
      }
      // 応募者募集中の会場が最大個数を超えた場合、最後の会場を募集停止に強制変更
      const venueData = selectionVenues.value[selectionVenues.value.length - 1];
      venueData.active = false;
      const res = await updateActiveVenue(venueData);
      if (res.success === true) await fetchAll();
    };
    // 応募者登録
    const registerApplicantLocal = async payload => {
      await registerApplicant(
        payload.selectedApplicants,
        store,
        refModalSelectionsApplicant.value,
      );
      isVisibleModalSelectionsApplicant.value = false;
      fetchAll();
      await store.dispatch('page/SET_LOADED');
    };
    const scrollToBottom = async () => {
      const clientRect = document
        .querySelector('.venueList-foot')
        .getBoundingClientRect();
      for (let y = 0; y <= 4200; y += 100) {
        window.scrollTo({
          top: window.pageYOffset + clientRect.top,
          left: 0,
          behavior: 'smooth',
        });
      }
      await scrollDelay(100);
    };
    const validationSelectionBasicInfo = () => {
      return refSelectionsBasicInfo.value.validateBasicInfo();
    };
    // 全ての選考会場でバリデーション実行
    const validateVenues = () => {
      return refSelectionsVenue.value
        .map(refSelectionsVenue =>
          refSelectionsVenue.validateVenueAndTimetables(),
        )
        .every(isValid => isValid === true);
    };
    // 選考会場の追加（ローカル）
    const addLocalVenue = () => {
      // 新規作成時のみ会場登録は1つまで
      if (!props.id && selectionVenues.value.length >= 1) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '選考新規作成時の選考会場の登録数の上限は1です',
          type: false,
        });
        return;
      }
      // 編集時は会場登録は300個まで
      if (props.id && selectionVenues.value.length >= MAX_VENUE_LENGTH) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `選考会場の登録数の上限は${MAX_VENUE_LENGTH}です`,
          type: false,
        });
        return;
      }
      if (
        !selection.data.venues ||
        selectionVenues.value.filter(venue => venue.active).length >=
          MAX_ACTIVE_VENUE_LENGTH
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `応募者を募集できる選考会場の上限は${MAX_ACTIVE_VENUE_LENGTH}会場です。`,
          type: false,
        });
        return;
      }
      const venue = new VenueModel();
      // Google連携済みかつGoogle WorkSpaceスタッフの場合、isCreateGoogleCalendarはデフォルトtrue
      isConnectedGoogleCalendar.value === true && isGoogleMember.value === true
        ? venue.addTimeTable({ isCreateGoogleCalendar: true })
        : venue.addTimeTable();
      // selectStaffsは手動で付与
      venue.timetables[0].selectStaffs = cloneDeep(staffs.value);
      // selectResourcesは手動で付与
      venue.timetables[0].selectResources = cloneDeep(googleResources.value);
      // selectZoomHostsは手動で付与
      venue.timetables[0].selectZoomHosts = cloneDeep(zoomUsers.value);
      addVenueData(venue);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: '選考会場を追加しました',
        type: true,
      });
    };
    // 会場データを複製（ローカル）
    const copyLocalVenue = venue => {
      if (
        selectionVenues.value.filter(Venue => Venue.active).length >=
        MAX_ACTIVE_VENUE_LENGTH
      ) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: `応募者を募集できる選考会場の上限は${MAX_ACTIVE_VENUE_LENGTH}会場です。`,
          type: false,
        });
        return;
      }
      venue.copy().then(async newVenue => {
        newVenue.timetables = newVenue.timetables.map((timetable, i) => {
          // selectStaffsはmodelでコピーされないので手動でコピー
          timetable.selectStaffs = cloneDeep(venue.timetables[i].selectStaffs);
          // selectResourcesはmodelでコピーされないので手動でコピー
          timetable.selectResources = cloneDeep(
            venue.timetables[i].selectResources,
          );
          // selectZoomHostsは手動で付与
          timetable.selectZoomHosts = cloneDeep(
            venue.timetables[i].selectZoomHosts,
          );
          // Google連携済みかつGoogle WorkSpaceスタッフの場合、isCreateGoogleCalendarはデフォルトtrue
          if (
            isConnectedGoogleCalendar.value === true &&
            isGoogleMember.value === true
          ) {
            timetable.isCreateGoogleCalendar = true;
          }
          return timetable;
        });
        addVenueData(newVenue);
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '選考会場を複製しました',
          type: true,
        });
        await setTimeout(scrollToBottom, 100);
      });
    };
    const movePager = tmpPage => {
      fetchApplicants(tmpPage ? tmpPage.next : 1, store);
    };
    // windowのサイズで表示非表示カラムを変更
    const changeApplicantTable = () => {
      const normalInvisibleColumns = [
        'attendanceDate',
        'invited_status',
        'invited_at',
      ];
      const responsiveInvisibleColumns = [
        'event_title',
        'attendanceDate',
        'not_announe_lamp',
        'name_kana',
        'gender',
        'school',
        'faculty',
        'department',
        'department_category',
        'email',
        'tel',
        'channel',
        'invited_status',
        'invited_at',
        'created_at',
        'activated_at',
        'staff',
      ];
      invisibleColumns.value =
        window.innerWidth >= Breakpoints.MAX_SP_SIZE
          ? normalInvisibleColumns
          : responsiveInvisibleColumns;
    };
    const scrollDelay = ms => new Promise(res => setTimeout(res, ms));
    const onResize = () => {
      if (timeoutId.value) return;
      timeoutId.value = setTimeout(() => {
        timeoutId.value = 0;
        changeApplicantTable();
      }, 500);
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchAll();
      changeApplicantTable();
      if (props.initialDate !== null) {
        updateVenueDataByKey(0, 'date', props.initialDate);
      }
    });
    onMounted(() => {
      window.addEventListener('resize', onResize);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', onResize);
    });

    return {
      rejectedRoleIds: [3],
      rejectedAdminIds: [2],
      MAX_VENUE_LENGTH,
      MAX_TIMETABLE_LENGTH,
      staffs,
      events,
      applicants,
      selection,
      applicantSearch,
      invisibleColumns,
      applicantsNotAnnounce,
      sortDate,
      bulkAssignTargetVenueId,
      timeoutId,
      googleResources,
      zoomUsers,
      refSelectionDetailBody,
      refSelectionsBasicInfo,
      refSelectionsVenue,
      refModalSelectionsApplicant,
      isLoading,
      isConnectedGoogleCalendar,
      isVisibleModalSelectionsApplicant,
      isVisibleModalBulkAnnounceSkip,
      isVisibleModalBulkAssignmentStaff,
      enableZoom,
      selectionVenues,
      graduatedId,
      isGoogleMember,
      isRegistrationSelection,
      isEditSelection,
      isLocalStateLoaded,
      fetchAll,
      fetchSelectionDetail,
      openModalBulkAnnounceSkip,
      onClickBulkAnnounceSkip,
      onClickSortDate,
      cerateSelectionLocal,
      onAfterAddedVenue,
      createEvent,
      updateSelectionBasic,
      updateSelectionEvent,
      updateSelectionBasicInRegistration,
      deleteSelection,
      createVenue,
      deleteVenue,
      updateVenue,
      updateTimetable,
      deleteTimetable,
      updateVenueData,
      addTimetableInVenueData,
      updateActiveVenue,
      sendAssignmentReminder,
      updateTimetableDataByIndexInVenueData,
      updateVenueDataByKey,
      updateGoogleEvent,
      updateZoomMeeting,
      deleteZoomMeeting,
      registerApplicantLocal,
      downloadApplicantCsv,
      resetStaffsSelected,
      validationSelectionBasicInfo,
      validateVenues,
      addLocalVenue,
      copyLocalVenue,
      onApplicantsChangeSort,
      movePager,
      selectStaff,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.si-block {
  margin-bottom: 20px;
  background-color: #fff;
  > .headline {
    padding: 10px 20px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    background-color: #4397d1;
  }
  > .headline > .icon {
    margin-right: 10px;
  }
  .inner {
    padding: 30px;
  }
  .toggle-edit-pencil {
    display: inline-block;
    margin-right: 6px;
  }
  .toggle-edit-check {
    display: none;
    margin-right: 6px;
  }
}

.btns-area {
  .btns {
    margin: 10px 6px 0 0;
  }
}

.register_applicant_block {
  margin: 20px 0;
}

.area-table {
  margin-bottom: 20px;
}

.newRegisterBtnArea {
  margin-bottom: 20px;
  padding: 20px 0;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

.registerApplicant-btns {
  text-align: center;
}

.list-applicant-title {
  width: 100%;
  padding: 10px;
  line-height: 1.4;
  color: #1899d6;
  font-weight: bold;
  background-color: #e9f3f8;
}

.venueList-head {
  display: flex;
  align-items: center;
  padding: 30px 30px 0;
  .countVenues {
    padding-left: 20px;
  }
}

.venueList-body {
  padding: 0 30px;
}

.venueList-foot {
  margin: 30px 30px 0;
  padding: 30px 0;
  border-top: 1px solid #e2e2e2;
}
.btns-area-venue {
  display: flex;
  width: 100%;
  > div {
    margin-right: 10px;
    &:last-child {
      display: flex;
      margin-left: auto;
      margin-right: 0;
      > p {
        margin-right: 10px;
        line-height: 36px;
      }
    }
  }
}

.btns-area-bottom {
  margin-top: 30px;
  .registration-button {
    width: 100%;
    height: 64px;
    line-height: 44px;
  }
}
.icon-sortable {
  border: solid 1px #16b2d9;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  &-svg-up {
    width: 1.2rem;
    fill: none;
    stroke-width: 2;
    stroke: $mochica_color;
  }
  &-svg-down {
    @extend .icon-sortable-svg-up;
    width: 1.2rem;
    fill: none;
    stroke-width: 2;
    stroke: $mochica_color;
    transform: rotate(180deg);
  }
  > img {
    margin-left: 5px;
  }
  svg {
    transform: rotate(180deg);
    height: 0.8rem;
    &.icon-sortable-svg-down {
      transform: rotate(0deg);
    }
  }
}

.icon-download {
  margin-right: 6px;
}

@media (max-width: ($media_query_sp)) {
  .si-block {
    .inner {
      padding: 20px;
    }
  }
  .btns-area {
    text-align: left;
  }
  .venueList-head {
    display: block;
    padding: 20px 20px 0;
    .countVenues {
      padding: 20px 0 0;
    }
  }
  .venueList-body {
    padding: 0 20px;
  }
  .venueList-foot {
    margin: 20px 20px 0;
    padding: 20px 0;
  }
  .btns-area-venue {
    display: block;
    > div {
      margin: 0 0 10px 0;
    }
  }
}
</style>
