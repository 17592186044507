<template>
  <div class="home_content">
    <div class="home_header">
      <p class="home_sub_title">直近3日間の選考状況</p>
      <p class="home_caption">直近3日以内の状況です。</p>
    </div>
    <div class="home_counter_group">
      <div class="home_counter">
        <dl
          class="is-selection_reserves"
          @click="$router.push({ name: 'ApplicantsSelectionReserves' })"
        >
          <div class="counter_content">
            <dt>直近の選考予約</dt>
            <dd>{{ selectionReservesCount }}</dd>
          </div>
        </dl>
      </div>
      <div class="home_counter">
        <dl
          class="is-applicants"
          @click="$router.push({ name: 'ApplicantsEnteredApplicants' })"
        >
          <div class="counter_content">
            <dt>直近の応募者</dt>
            <dd>{{ enteredApplicantsCount }}</dd>
          </div>
        </dl>
      </div>
      <div class="home_counter">
        <dl
          class="is-files"
          @click="$router.push({ name: 'ApplicantsUploads' })"
        >
          <div class="counter_content">
            <dt>直近のファイル</dt>
            <dd>{{ filesCount }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import applicantService from '@/services/applicant';

export default defineComponent({
  name: 'HomeLatest',
  setup(props, context) {
    const store = useStore();
    const selectionReservesCount = ref(null);
    const enteredApplicantsCount = ref(null);
    const filesCount = ref(null);

    const fetchAll = async () => {
      // 並行処理
      const res = await Promise.all([
        applicantService.getSelectionReservesSummary(),
        applicantService.getEnteredApplicantsSummary(),
        applicantService.getEnteredUploadsSummary(),
      ]);
      if (res[0].success === true) {
        selectionReservesCount.value = res[0].count_summary;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res[0].message,
          type: false,
        });
      }
      if (res[1].success === true) {
        enteredApplicantsCount.value = res[1].count_summary;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res[1].message,
          type: false,
        });
      }
      if (res[2].success === true) {
        filesCount.value = res[2].count_summary;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res[2].message,
          type: false,
        });
      }
    };

    return {
      selectionReservesCount,
      enteredApplicantsCount,
      filesCount,
      fetchAll,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.home_header {
  display: flex;
  margin-bottom: 24px;
}

.home_sub_title {
  font-size: 20px;
  font-weight: bold;
}

.home_caption {
  padding-left: 15px;
  font-size: 14px;
  align-content: center;
  color: #777777;
}

.home_content {
  padding: 30px 30px 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.11);
}

.home_counter_group {
  display: flex;
  justify-content: space-between;
}

.home_counter {
  min-height: 94px;
  min-width: 33%;
  padding: 17px 20px;
  margin-bottom: 12px;
  border: 1px solid #d7ebf5;
  border-radius: 4px;
  background: #f3f9fc;
  cursor: pointer;
  > dl {
    line-height: 1;
    display: flex;
    &::before,
    &::after {
      content: '';
      width: 20px;
      height: auto;
      background-position: center;
      background-size: auto 35px;
      background-repeat: no-repeat;
      pointer-events: none;
    }
    &::before {
      background-size: auto 35px;
    }
    &::after {
      background-image: url('../../../../assets/img/arrow_right_blue.svg');
      background-size: auto 20px;
    }
    > div {
      width: 100%;
      > dt {
        position: relative;
        padding-left: 22px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
      > dd {
        padding-left: 22px;
        font-size: 32px;
        font-weight: bold;
        color: #1c91c8;
      }
    }
    &.is-selection_reserves {
      &::before {
        width: 45px;
        background-image: url('../../../../assets/img/two_men_blue.svg');
      }
    }
    &.is-applicants {
      &::before {
        width: 45px;
        background-image: url('../../../../assets/img/man_blue.svg');
      }
    }
    &.is-files {
      &::before {
        width: 45px;
        background-image: url('../../../../assets/img/icon_file_white.svg');
      }
    }
  }
}
</style>
