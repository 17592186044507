<template>
  <li class="flag_survey_form">
    <div class="question_head">
      <p>質問 {{ index }}</p>
      <button-base
        v-if="isEditable"
        type="button"
        class="btn btn-unavailable btn-delete"
        :button-type="'secondary'"
        :button-text="'削除'"
        :icon-file-name="'trash'"
        :disabled="questions.length <= 1"
        @click="deleteQuestion"
      />
    </div>
    <div class="question_content">
      <div v-if="isEntryForm !== true" class="flag_survey_flex">
        <p class="flag_survey_heading">回答形式</p>
        <div
          class="input_tr"
          :class="{ 'is-font_small': isEntryForm === true }"
        >
          <drop-down
            v-model="localQuestionType"
            class="toSelect"
            :placeholder="'回答形式'"
            :selected-id="localQuestionType"
            :list="questionTypes"
            :is-view="isShowDropDownQuestionType"
            :disabled="!isEditable || questionTagGroups.length === 0"
            @onOpen="isShowDropDownQuestionType = true"
            @onClose="isShowDropDownQuestionType = false"
            @onSelected="onSelectedQuestionType($event)"
          />
        </div>
      </div>
      <div class="flag_survey_flex">
        <p
          class="flag_survey_heading"
          :style="{ paddingTop: isEntryForm ? '0' : '16px' }"
        >
          質問文
          <span class="flag_survey_heading_caption">（最大80文字まで）</span>
        </p>
        <div
          class="input_tr"
          :class="{ 'is-font_small': isEntryForm === true }"
        >
          <textarea
            v-model="localQuestionTitle"
            type="text"
            maxlength="80"
            :name="`questionTitle${questionId}`"
            placeholder="質問文を入力してください"
            class="flag_survey_input input_block"
            :class="{
              'is-danger': !errors[`questionTitle${questionId}`].isValid,
            }"
            :disabled="!isEditable"
            @input="
              $emit('update-question-title', {
                questionId: questionId,
                questionTitle: localQuestionTitle,
              })
            "
          />
          <span
            v-show="!errors[`questionTitle${questionId}`].isValid"
            class="fa fa-warning font-s"
          >
            {{ errors[`questionTitle${questionId}`].message }}
          </span>
        </div>
      </div>
      <template v-if="localQuestionType === 1">
        <div class="flag_survey_flex">
          <p class="flag_survey_heading_checkbox">複数回答</p>
          <div class="input_tr">
            <p
              class="flag_survey_checkbox"
              :class="{ 'is-disabled': !isEditable }"
            >
              <input
                :id="`multi${questionId}`"
                v-model="localMulti"
                :name="`multi${questionId}`"
                type="checkbox"
                class="setting_input"
                :disabled="!isEditable"
                @change="
                  $emit('update-multi', {
                    questionId: questionId,
                    multi: localMulti,
                    questionFlagGroupId: localQuestionFlagGroupId,
                  })
                "
              />
              <label :for="`multi${questionId}`">複数回答を有効にする</label>
            </p>
          </div>
        </div>
        <div class="flag_survey_flex target_group">
          <p class="flag_survey_heading">対象フラググループ</p>
          <div
            class="input_tr"
            :class="{ 'is-font_small': isEntryForm === true }"
          >
            <drop-down
              v-model="localQuestionFlagGroupId"
              class="toSelect"
              :name="`questionTagGroups${questionId}`"
              :placeholder="'対象フラググループ'"
              :selected-id="localQuestionFlagGroupId"
              :list="questionTagGroups"
              :is-view="isShowDropDown"
              :class="{
                question_tag_groups: true,
                'is-danger': !errors[`questionTagGroups${questionId}`].isValid,
              }"
              :disabled="!isEditable"
              data-vv-as="対象フラググループ"
              @onOpen="isShowDropDown = true"
              @onClose="isShowDropDown = false"
              @onSelected="onSelectedTagGroupId($event)"
            />
            <span
              v-show="!errors[`questionTagGroups${questionId}`].isValid"
              class="fa fa-warning font-s"
            >
              {{ errors[`questionTagGroups${questionId}`].message }}
            </span>
          </div>
        </div>
      </template>
    </div>
  </li>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue';
import DropDown from '@/components/ui/menus/components/DropDown';
import useValidation from '@/composables/useValidation';

export default defineComponent({
  name: 'FlagSurveyQuestion',
  components: { DropDown },
  props: {
    componentKey: {
      type: String,
      default: '',
    },
    questionId: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    questionTitle: {
      type: String,
      default: '',
    },
    questionType: {
      type: Number,
      default: null,
    },
    questionMulti: {
      type: Boolean,
      default: false,
    },
    questionFlagGroupId: {
      type: Number,
      default: null,
    },
    questionTagGroups: {
      type: Array,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    isEntryForm: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update-question-title',
    'update-question-type',
    'update-multi',
    'update-tag-groups',
    'delete-question',
  ],
  setup(props, context) {
    const isShowDropDown = ref(false);
    const isShowDropDownQuestionType = ref(false);
    const localQuestionTitle = ref('');
    const localQuestionType = ref(1);
    const localMulti = ref(false);
    const localQuestionFlagGroupId = ref(null);
    const questionTypes = ref([
      { id: 1, name: 'フラグから選択', selected: true },
      { id: 2, name: '自由記述', selected: false },
    ]);
    const { errors, resetErrors, validateEmoji, validateRequire } =
      useValidation([
        `questionTitle${props.questionId}`,
        `questionTagGroups${props.questionId}`,
      ]);

    // methods
    const onSelectedQuestionType = event => {
      localQuestionType.value = event.item.id;
      questionTypes.value = questionTypes.value.map(v => {
        if (v.id === localQuestionType.value) return { ...v, selected: true };
        return { ...v, selected: false };
      });
      context.emit('update-question-type', {
        questionId: props.questionId,
        questionType: localQuestionType.value,
      });
      onSelectedTagGroupId({ item: { id: null } });
    };
    const onSelectedTagGroupId = event => {
      localQuestionFlagGroupId.value = event.item.id;
      context.emit('update-tag-groups', {
        questionId: props.questionId,
        questionFlagGroupId: localQuestionFlagGroupId.value,
      });
      // nextTickが効かない
      setTimeout(() => (isShowDropDown.value = false), 10);
    };
    const deleteQuestion = () => {
      // 質問を1つより少なくはできない
      if (props.questions.length <= 1) return;
      context.emit('delete-question', {
        questionId: props.questionId,
        questionFlagGroupId: localQuestionFlagGroupId.value,
      });
    };
    const validate = () => {
      resetErrors();
      let isValid = true;
      if (
        validateRequire(
          `questionTitle${props.questionId}`,
          localQuestionTitle.value,
        ) !== true ||
        validateEmoji(
          `questionTitle${props.questionId}`,
          localQuestionTitle.value,
        ) !== true
      ) {
        isValid = false;
      }
      if (
        localQuestionType.value === 1 &&
        validateRequire(
          `questionTagGroups${props.questionId}`,
          localQuestionFlagGroupId.value,
          'select',
        ) !== true
      ) {
        isValid = false;
      }
      if (isValid !== true) {
        return { success: false, message: '入力内容を確認してください' };
      }
      return { success: true, message: '' };
    };

    const updateLocalQuestion = () => {
      localQuestionTitle.value = props.questionTitle;
      localQuestionType.value = props.questionType;
      localMulti.value = props.questionMulti;
      localQuestionFlagGroupId.value = props.questionFlagGroupId;
    };

    watch(
      () => props.componentKey,
      () => {
        updateLocalQuestion();
      },
    );

    // lifecycle
    onMounted(() => {
      updateLocalQuestion();
    });

    return {
      isShowDropDown,
      isShowDropDownQuestionType,
      localQuestionTitle,
      localMulti,
      localQuestionType,
      localQuestionFlagGroupId,
      questionTypes,
      onSelectedQuestionType,
      onSelectedTagGroupId,
      deleteQuestion,
      errors,
      validate,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include validation_effects3;

.flag_survey_form {
  text-align: left;
  margin-bottom: 16px;
  border: 1px solid $mochica_color_gray2;
  border-radius: 4px;
}

.question_head {
  display: flex;
  align-items: center;
  padding: 9px 20px;
  background: #f9f9f9;
  border-bottom: 1px solid $mochica_color_gray2;
  border-radius: 3px 3px 0 0;
  > p {
    margin-right: auto;
    font-size: $font_14;
    font-weight: bold;
    line-height: 1;
    color: $mochica_color;
  }
  > :deep(button.btn-base.btn-delete) {
    &:disabled,
    &:disabled.btn-outlined {
      color: $white;
      background-color: #999;
      span::before {
        background-color: $white;
      }
    }
  }
}

.question_content {
  padding: 16px 16px 0 16px;
}

.flag_survey_flex {
  display: block;
  padding-bottom: 16px;
  &.target_group {
    padding-top: 16px;
  }
  & + & {
    border-top: 1px solid $mochica_color_gray2;
  }
}

.flag_survey_heading {
  display: flex;
  min-width: 152px;
  font-size: $font_14;
  font-weight: bold;
  line-height: 1;
  margin-right: 10px;
  text-align: left;
  margin-bottom: 8px;
  .flag_survey_heading_caption {
    font-weight: normal;
  }
}

.flag_survey_heading_checkbox {
  min-width: 152px;
  max-width: 152px;
  margin: 16px 10px 8px 0;
  font-size: $font_14;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
}

.input_tr {
  position: relative;
  width: 100%;
  max-width: 750px;
  margin-right: auto;
  .input_block {
    display: block;
  }
  :deep(.ui-select) {
    border: 1px solid #d6d6d6;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: $font_14;
  }

  &.is-font_small :deep(.ui-select) {
    font-size: $font_14;
  }
}

.toSelect {
  max-width: 330px;
  display: block;
  &.is-danger {
    border: none !important;
    :deep(.ui-select) {
      border: 1px $mochica_color_red solid;
    }
  }
}

:deep(.is-disabled.ui-select) {
  cursor: default;
}

.flag_survey_input {
  width: 100%;
  margin-bottom: 0;
  color: #333;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: $font_14;
  line-height: 1.4;
  padding: 16px;
  &:disabled {
    -webkit-text-fill-color: #333;
    opacity: 1;
  }
}

.question_tag_groups {
  margin-bottom: 5px;
}

.flag_survey_checkbox {
  position: relative;
  display: flex;
  font-size: $font_14;
  .setting_input {
    appearance: none;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 0.4rem;
    border: 2px solid #9d9d9d;
    background: #fff;
    position: relative;
    cursor: pointer;
    &:checked {
      &::after {
        content: url(../../../../assets/img/check.svg);
        height: 1.1rem;
        width: 1.5rem;
        margin: -5px 0 0 1px;
        position: absolute;
        bottom: 3px;
        left: 0;
      }
    }
    &:disabled {
      cursor: default;
    }
  }
  label {
    display: inline-block;
    padding: 0 0 0 10px;
    font-size: $font_14;
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
  }
  &.is-disabled label {
    cursor: default;
  }
}
</style>
