<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import BaseInput from '@/components/ui/input/BaseInput.vue';
import BaseSelect from '@/components/ui/select/BaseSelect.vue';
import BaseCheckbox from '@/components/ui/checkbox/BaseCheckbox.vue';
import AutoActionsTemplate from '@/components/features/autoActions/templates/AutoActionsTemplate.vue';
import SelectionsSearchModal from '@/components/features/autoActions/modals/SelectionsSearchModal.vue';

import GraduatedDefines from '@/defines/graduated';
import { useInitialGraduated } from '@/composables/useInitialGraduated';

import { ACTION_TYPE_LABELS } from '@/defines/autoActions';

import ProgressStatusLabel from '@/components/features/progress/ProgressStatusLabel.vue';
import EmptyContent from '@/components/ui/empty/EmptyContent.vue';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import autoActionsService from '@/services/autoActions.js';
import type { AutoAction } from '@/types/autoActions';
import { ACTION_TYPES } from '@/defines/autoActions';

type AutoActionWithCopy = AutoAction & {
  isSelected: boolean;
  actionName: { value: string; isValid: boolean };
  targetSelection: {
    value: { id: number; title: string } | null;
    isValid: boolean;
  };
  assignSelection: {
    value: { id: number; title: string } | null;
    isValid: boolean;
  };
};

const store = useStore();
const router = useRouter();

const autoActions = ref<AutoActionWithCopy[]>([]);
const isLoading = ref(false);
const isSubmitting = ref(false);
const isViewSelectionSearchModal = ref(false);
const selectSelectionMode = ref<{
  action: AutoActionWithCopy | null;
  type: 'target' | 'assign' | null;
}>({
  action: null,
  type: null,
});
const { graduates, selectedGraduateId, initializeGraduatedSelection } =
  useInitialGraduated();

const graduatedYearToLabelName = (year: number) => {
  const res = GraduatedDefines.find(graduated => graduated.year === year);
  if (res !== undefined) return res.name_jp;
  return `${year}年卒`;
};

const fetchAutoActions = async () => {
  isLoading.value = true;
  const { data } = await autoActionsService.fetchAutoActions({
    graduated_id: selectedGraduateId.value,
  });
  autoActions.value = data.actions.map((action: AutoAction) => ({
    ...action,
    actionName: { value: action.action_name, isValid: true },
    targetSelection: { value: null, isValid: true },
    assignSelection: { value: null, isValid: true },
    isSelected: false,
  }));
  isLoading.value = false;
};

const handleChangeGraduate = async (value: number) => {
  selectedGraduateId.value = value;
  await fetchAutoActions();
};

const handleChangeAllSelect = (value: boolean) => {
  for (const action of autoActions.value) {
    action.isSelected = value;
  }
};

const handleChangeActionName = (value: string, action: AutoActionWithCopy) => {
  action.actionName.value = value;
  action.actionName.isValid = value.length > 0;
};

const handleChangeSelect = (value: boolean, action: AutoActionWithCopy) => {
  action.isSelected = value;
};

const handleSelectSelection = ({
  id,
  title,
  description,
}: {
  id: number;
  title: string;
  description: string;
}) => {
  const targetAction = autoActions.value.find(
    action => action.id === selectSelectionMode.value.action?.id,
  );
  if (!targetAction) return;

  if (selectSelectionMode.value.type === 'target') {
    targetAction.targetSelection = { value: { id, title }, isValid: true };
  } else {
    targetAction.assignSelection = { value: { id, title }, isValid: true };
  }
  isViewSelectionSearchModal.value = false;
};

const onClickShowSelectionSearchModalButton = (
  type: 'target' | 'assign',
  action: AutoActionWithCopy,
) => {
  isViewSelectionSearchModal.value = true;
  selectSelectionMode.value.type = type;
  selectSelectionMode.value.action = action;
};

const setValidateCopyAction = () => {
  const target = autoActions.value.filter(action => action.isSelected);

  for (const action of target) {
    if (action.actionName.value.length === 0) {
      action.actionName.isValid = false;
    }
    if (action.targetSelection.value === null) {
      action.targetSelection.isValid = false;
    }
    if (
      action.action_type === ACTION_TYPES.assignProgress &&
      action.assignSelection.value === null
    ) {
      action.assignSelection.isValid = false;
    }
  }
};

const isValidateCopyAction = () => {
  return autoActions.value
    .filter(action => action.isSelected)
    .every(
      action =>
        action.actionName.isValid &&
        action.targetSelection.isValid &&
        action.assignSelection.isValid,
    );
};

const handleSubmit = async () => {
  setValidateCopyAction();
  if (!isValidateCopyAction()) {
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message:
        '必須項目が未入力です アクション名、対象の選考、割り当て先の選考を確認してください',
      type: false,
    });
    return;
  }

  const requestParams = {
    from_graduated: selectedGraduateId.value,
    to_graduated: store.getters['graduateds/selectedGraduatedId'],
    actions: autoActions.value
      .filter(action => action.isSelected)
      .map(action => ({
        id: action.id,
        name: action.actionName.value,
        selection_id: action.targetSelection.value?.id ?? null,
        assign_progress_id: action.assignSelection.value?.id ?? null,
      })),
  };
  isSubmitting.value = true;
  const res = await autoActionsService.copyAutoActions(requestParams);
  isSubmitting.value = false;

  store.dispatch('notification/VISIBLE_NOTIFICATION', {
    message: res.data.success
      ? '自動アクションの複製に成功しました'
      : res.data.message,
    type: res.data.success,
  });
  if (res.data.success) {
    router.push({ name: 'AutoActions' });
  }
};

const getExcludeSelectionIds = computed(() => {
  if (!selectSelectionMode.value.type || !selectSelectionMode.value.action?.id)
    return [];

  const targetAction = autoActions.value.find(
    action => action.id === selectSelectionMode.value.action?.id,
  );
  if (!targetAction) return [];

  const oppositeSelection =
    selectSelectionMode.value.type === 'target'
      ? targetAction.assignSelection?.value
      : targetAction.targetSelection?.value;

  return oppositeSelection ? [oppositeSelection.id] : [];
});

onMounted(async () => {
  store.dispatch('page/SET_LOADED');
  await initializeGraduatedSelection();
  await fetchAutoActions();
});
</script>

<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <auto-actions-template
        :header-title="'既存の自動アクションから複製'"
        :back-page-title="'自動アクション一覧に戻る'"
        :on-back-page="() => router.push({ name: 'AutoActions' })"
      >
        <template #titleDescription>
          <span class="title-description">
            ※自動アクション名・対象の選考は引き継がれません。複製する自動アクションの名前と選考を入力してください。
            <br />
            複製する自動アクションに「新しく選考を割り当てる」が含まれる場合、対象の割り当てる選考を選択してください。
          </span>
        </template>
        <template #body>
          <div class="section-body-inner">
            <div class="graduate-select-wrapper">
              <label class="graduate-select-label">卒年の選択</label>
              <base-select
                :model-value="selectedGraduateId"
                @change="handleChangeGraduate($event.target.value)"
              >
                <option
                  v-for="graduate in graduates"
                  :key="graduate.id"
                  :value="graduate.id"
                >
                  {{ graduatedYearToLabelName(graduate.year) }}
                </option>
              </base-select>
            </div>
            <div v-if="isLoading">
              <local-page-loader />
            </div>
            <div v-else-if="autoActions.length === 0">
              <empty-content>
                登録済みの自動アクションはありません
              </empty-content>
            </div>
            <div v-else class="table-wrapper">
              <table class="table">
                <thead>
                  <th class="fit-left">
                    <base-checkbox
                      :model-value="
                        autoActions.length ===
                        autoActions.filter(action => action.isSelected).length
                      "
                      @change="handleChangeAllSelect($event.target.checked)"
                    />
                  </th>
                  <th><span>自動アクション名</span></th>
                  <th><span>対象の選考</span></th>
                  <th><span>対象のステータス</span></th>
                  <th><span>アクション</span></th>
                  <th><span>割り当てる選考</span></th>
                  <th class="fit-left">
                    <span>フラグ指定</span>
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="action in autoActions"
                    :key="action.id"
                    :class="{ 'is-selected': action.isSelected }"
                  >
                    <td class="fit-left">
                      <base-checkbox
                        :model-value="action.isSelected"
                        @change="
                          handleChangeSelect($event.target.checked, action)
                        "
                      />
                    </td>
                    <td>
                      <span class="action-name">
                        {{ action.action_name }}
                      </span>
                      <div v-show="action.isSelected" class="action-name-input">
                        <label class="action-name-label">
                          複製する自動アクションの名前・選考
                        </label>
                        <base-input
                          :model-value="action.actionName.value"
                          :is-error="!action.actionName.isValid"
                          maxlength="50"
                          @change="
                            handleChangeActionName($event.target.value, action)
                          "
                        />
                        <span
                          v-show="!action.actionName.isValid"
                          class="error-message"
                        >
                          アクション名を入力してください
                        </span>
                      </div>
                    </td>
                    <td>
                      <span>{{ action.trigger_conditions.event_name }}</span>
                      <div
                        v-show="action.isSelected"
                        class="select-selection-wrapper"
                      >
                        <span
                          v-show="action.targetSelection.value"
                          class="select-selection-title"
                        >
                          {{ action.targetSelection?.value?.title || '-' }}
                        </span>
                        <button-base
                          type="button"
                          :button-text="
                            action.targetSelection.value
                              ? '再選択'
                              : '選考を選択'
                          "
                          @click="
                            onClickShowSelectionSearchModalButton(
                              'target',
                              action,
                            )
                          "
                        />
                        <span
                          v-show="!action.targetSelection.isValid"
                          class="error-message"
                        >
                          対象の選考を選択してください
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="progress-statuses">
                        <progress-status-label
                          v-for="progress in [
                            ...action.trigger_conditions.progress_status_ids,
                            ...action.trigger_conditions
                              .finished_progress_status_ids,
                          ]"
                          :key="progress.name"
                          :label-name="progress.name"
                          class="w-fit sm"
                        />
                      </div>
                      <div v-show="action.isSelected" class="progress-statuses">
                        <progress-status-label
                          v-for="progress in [
                            ...action.trigger_conditions.progress_status_ids,
                            ...action.trigger_conditions
                              .finished_progress_status_ids,
                          ]"
                          :key="progress.name"
                          :label-name="progress.name"
                          class="w-fit sm"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="action-type-label">
                        {{ ACTION_TYPE_LABELS[action.action_type] }}
                      </div>
                      <div v-show="action.isSelected" class="action-type-label">
                        {{ ACTION_TYPE_LABELS[action.action_type] }}
                      </div>
                    </td>
                    <td>
                      <span>
                        {{
                          action.action_type === ACTION_TYPES.assignProgress
                            ? action.action_conditions.selection?.event_name
                            : '-'
                        }}
                      </span>
                      <div
                        v-show="action.isSelected"
                        class="select-selection-wrapper"
                      >
                        <span
                          v-show="action.assignSelection.value"
                          class="select-selection-title"
                        >
                          {{ action.assignSelection.value?.title || '-' }}
                        </span>
                        <button-base
                          v-show="
                            action.action_type === ACTION_TYPES.assignProgress
                          "
                          type="button"
                          :button-text="
                            action.assignSelection.value
                              ? '再選択'
                              : '選考を選択'
                          "
                          @click="
                            onClickShowSelectionSearchModalButton(
                              'assign',
                              action,
                            )
                          "
                        />
                        <span
                          v-show="!action.assignSelection.isValid"
                          class="error-message"
                        >
                          割り当てる選考を選択してください
                        </span>
                      </div>
                    </td>
                    <td class="fit-left">
                      <div class="flag-status">
                        {{
                          action.trigger_conditions.has_flag ? 'あり' : 'なし'
                        }}
                      </div>
                      <div v-show="action.isSelected" class="flag-status">
                        {{
                          action.trigger_conditions.has_flag ? 'あり' : 'なし'
                        }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="page-footer">
            <div class="cancel-button-wrapper">
              <button-base
                type="button"
                class="btn-cancel"
                button-type="secondary"
                button-text="キャンセル"
                @click="() => router.push({ name: 'AutoActions' })"
              />
            </div>
            <div class="submit-button-wrapper">
              <button-base
                type="button"
                :button-text="'複製'"
                :disabled="
                  autoActions.filter(action => action.isSelected).length ===
                    0 || isSubmitting
                "
                @click="handleSubmit"
              />
              <p class="page-footer-caption">
                ※複製を行うと自動アクションは有効になります。
              </p>
            </div>
          </div>
        </template>
      </auto-actions-template>
    </div>
    <selections-search-modal
      :is-view="isViewSelectionSearchModal"
      :exclude-selection-ids="getExcludeSelectionIds"
      @close-modal="isViewSelectionSearchModal = false"
      @select-selection="handleSelectSelection({ ...$event })"
    />
  </div>
</template>
<style scoped lang="scss">
@import '@/assets/variables.scss';
@include actionCommon;
@include actionTemplate;
@include tabsWrapper;
@include tableStyle;

.page-footer {
  display: flex;
  gap: 12px;
  margin: 20px 0 0;
  width: 100%;
  & > button {
    width: 50%;
    height: 56px;
  }
  .submit-button-wrapper,
  .cancel-button-wrapper {
    width: 100%;

    & > button {
      width: inherit;
      height: 56px;
    }
  }
  .page-footer-caption {
    margin-top: 8px;
    text-align: center;
  }
}
.section-body-inner {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.page-wrapper {
  margin: 20px 0;
  padding: 0 20px;
  .page-inner {
    padding: 0 30px 40px 30px;
    background-color: $white;
  }
}
.page-header {
  padding: 0 0 32px;
  border-bottom: 1px solid $mochica_color_gray2;
}
.page-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.page-body-header {
  display: flex;
  flex-direction: column;
  .btn-base {
    width: fit-content;
  }
}
.graduate-select-wrapper {
  max-width: 320px;
}
.graduate-select-label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}
.page-body-title {
  margin-bottom: 20px;
  font-size: $font_20;
  font-weight: bold;
}

.table {
  tr {
    &:hover {
      background-color: initial;
    }
  }
  tr.is-selected {
    td {
      vertical-align: top;
    }
  }
}

.title-description {
  font-size: $font_14;
  color: $system_color;
}
.create-action-button {
  margin-bottom: 12px;
}
.progress-statuses {
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
  .progress-status-label {
    flex-basis: 100%;
  }
  .w-fit {
    width: fit-content;
    padding: 8px 16px;
  }
}
.caption {
  font-size: $font_12;
  color: $system_color;
}

.create-action-button {
  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.action-name {
  display: block;
  width: 210px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.action-name-input {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 8px;
  max-width: 210px;

  .action-name-label {
    font-size: $font_12;
    color: $system_color;
  }
}
.select-selection-wrapper {
  margin-top: 36px;

  .select-selection-title {
    display: inline-block;
    width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 12px;
    font-size: $font_14;
    line-height: 1;
  }
  .error-message {
    display: block;
    padding-top: 6px;
  }
}
.progress-statuses {
  & + & {
    margin-top: 28px;
  }
}
.action-type-label {
  & + & {
    margin-top: 48px;
  }
}
.flag-status {
  & + & {
    margin-top: 48px;
  }
}
.error-message {
  color: $mochica_color_red;
  font-size: $font_12;
  font-weight: bold;
}
.btn-base {
  height: 40px;
}
</style>
