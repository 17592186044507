import httpClient from '@/http-client';

const ApplicantManager = function _ApplicantManager() {
  const manager = Object.create(ApplicantManager.prototype);
  return manager;
};

ApplicantManager.prototype = {
  async getUnreadMessagesSummary() {
    const res = await httpClient.get('/applicant/unread_messages_summary');
    return res.data;
  },
  async getUnreadMessagesSummaryGraduatedYearsIdApplicant(graduatedYear) {
    const res = await httpClient.get(
      `/applicant/unread_messages_summary/${graduatedYear}/applicant`,
    );
    return res.data;
  },
  async getUnreadMessagesSummaryGraduatedYearsIdLineCount(graduatedYear) {
    const res = await httpClient.get(
      `/applicant/unread_messages_summary/${graduatedYear}/line_friend`,
    );
    return res.data;
  },
  async getUnreadMessagesGraduatedYearsId(graduatedYear) {
    const res = await httpClient.get(
      `/applicant/unread_messages/${graduatedYear}`,
    );
    return res.data;
  },
  async getSelectionReservesSummary() {
    const res = await httpClient.get('/applicant/selection_reserves_summary');
    return res.data;
  },
  async getSelectionReservesGraduatedYearsId(graduatedYear) {
    const res = await httpClient.get(
      `/applicant/selection_reserves/${graduatedYear}`,
    );
    return res.data;
  },
  async getEnteredApplicantsSummary() {
    const res = await httpClient.get('/applicant/entered_applicants_summary');
    return res.data;
  },
  async getEnteredApplicantsGraduatedYearsId(graduatedYear) {
    const res = await httpClient.get(
      `/applicant/entered_applicants/${graduatedYear}`,
    );
    return res.data;
  },
  async getEnteredUploadsSummary() {
    const res = await httpClient.get('/applicant/uploads_summary');
    return res.data;
  },
  async getUploadsGraduatedYearsId(graduatedYear) {
    const res = await httpClient.get(`/applicant/uploads/${graduatedYear}`);
    return res.data;
  },
  async fetchApplicantSearchTags(applicantIds = [], tagGroupIds = []) {
    const response = await httpClient.post('/applicant/search_tags', {
      tags: { applicant_ids: applicantIds, tag_group_ids: tagGroupIds },
    });
    if (response.status === 200) {
      const applicantsTags = response.data.tags.reduce((obj, item) => {
        obj[item.applicant_id] = item.tag_groups;
        return obj;
      }, {});
      const highlightTagGroupIds = response.data.taggroup_highlight_ids;
      return { applicantsTags, highlightTagGroupIds };
    }
    return null;
  },
  async bulkUpdateApplicantStaff(applicantIds, staffId) {
    const result = await httpClient.post(
      '/applicant/bulk_update_applicant_staff',
      { applicant_ids: applicantIds, staff_id: staffId },
    );
    return result.data;
  },
  async bulkUpdateProgressesComment(applicantIds, comment) {
    const response = await httpClient.post(
      '/applicant/bulk_update_progress_comment',
      { applicant_ids: applicantIds, progress: { comment } },
    );
    if (response.status === 200) return { success: true };
    return response.data;
  },
  async bulkUpdateProgressesStatus(applicantIds, comment, toStatus) {
    const response = await httpClient.post(
      '/applicant/bulk_update_progress_status',
      {
        applicant_ids: applicantIds,
        progress: { comment, to_status: toStatus },
      },
    );
    if (response.status === 200) return { success: true };
    return response.data;
  },
  async bulkDeleteApplicants(applicantIds) {
    const res = await httpClient.post('/applicant/bulk_delete', {
      applicant_ids: applicantIds,
    });
    return res.data;
  },
  async verifyCsv(formData, type) {
    formData.append('type', type);
    const result = await httpClient.post('/applicant/verify_csv', formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
    if (result.status === 200) {
      return { success: true, message: result.data.message };
    }
    // Error || Duplicate
    return {
      success: result.data.success,
      code: result.data.code,
      name: result.data.name,
      message: result.data.message,
      hasDuplicatedRows: result.data.duplicated_rows !== undefined,
      duplicatedRows: result.data.duplicated_rows,
      hasErrorRows: result.data.error_rows !== undefined,
      errorRows: result.data.error_rows,
    };
  },
  async importCsv(formData) {
    const res = await httpClient.post('/applicant/import_csv', formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
    return {
      success: res.data.success,
      message: res.data.message,
      data: res.data.applicants ? res.data : null,
    };
  },
  async getUnreadFlagsurveysSummary() {
    const res = await httpClient.get('/applicant/unread_flagsurveys_summary');
    return res.data;
  },
  async getUnreadFlagsurveysGraduatedYearsId(graduatedYear) {
    const res = await httpClient.get(
      `/applicant/unread_flagsurveys/${graduatedYear}`,
    );
    return res.data;
  },
  async getCanceledApplicantsSummary() {
    const res = await httpClient.get('/applicant/canceled_applicants_summary');
    return res.data;
  },
  async getCanceledApplicantsGraduatedYearsId(graduatedYear) {
    const res = await httpClient.get(
      `/applicant/canceled_applicants/${graduatedYear}`,
    );
    return res.data;
  },
  async getUnreservedApplicantsSummary() {
    const res = await httpClient.get(
      '/applicant/unreserved_applicants_summary',
    );
    return res.data;
  },
  async getUnreservedApplicantsGraduatedYearsId(graduatedYear) {
    const res = await httpClient.get(
      `/applicant/unreserved_applicants/${graduatedYear}`,
    );
    return res.data;
  },
};

export default ApplicantManager();
