import httpClient from '@/http-client';

const AutoActionsManager = function _AutoActionsManager() {
  const manager = Object.create(AutoActionsManager.prototype);
  return manager;
};

AutoActionsManager.prototype = {
  async fetchSelections(params) {
    const res = await httpClient.get(`/actions/selections`, {
      params,
    });
    return res.data;
  },
  async fetchAutoActions(params) {
    const res = await httpClient.get(`/actions`, {
      params,
    });
    return res;
  },
  async fetchAutoActionsApplicants(params, actionId) {
    const res = await httpClient.get(`/action_logs/${actionId}/applicants`, {
      params,
    });
    return res;
  },
  async fetchAutoActionsLogsDetail(params, actionId) {
    const res = await httpClient.get(`/action_logs/${actionId}`, {
      params,
    });
    return res;
  },
  async updateAutoActionsApplicants(params) {
    const res = await httpClient.post(`/action_logs/bulk/update`, params);
    return res;
  },
  async checkDuplicate(params) {
    const res = await httpClient.post(`/actions/duplicate`, params);
    return res;
  },
  async createAutoAction(data) {
    const headers =
      data instanceof FormData ? { 'content-type': 'multipart/form-data' } : {};
    const res = await httpClient.post(`/actions`, data, {
      headers,
    });
    return res;
  },
  async deleteAutoAction(actionId) {
    const res = await httpClient.delete(`/actions/${actionId}`);
    return res;
  },
  async fetchAutoActionsDetail(params, actionId) {
    const res = await httpClient.get(`/actions/${actionId}`, {
      params,
    });
    return res;
  },
  async editAutoAction(params, actionId) {
    const res = await httpClient.patch(`/actions/${actionId}`, params);
    return res;
  },
  async fetchAutoActionsLogs(params) {
    const res = await httpClient.get(`/action_logs`, {
      params,
    });
    return res;
  },
  async checkMatchAction(applicantId, params) {
    const res = await httpClient.post(
      `/applicants/${applicantId}/match-actions`,
      params,
    );
    return res;
  },
  async inUseAutoActions(params) {
    const res = await httpClient.get(`/actions/in_use`, {
      params,
    });
    return res;
  },
  async copyAutoActions(params) {
    const res = await httpClient.post(`/actions/copy`, params);
    return res;
  },
};

export default AutoActionsManager();
