<script setup lang="ts">
import { Applicant } from '@/types/applicant';
import utils from '@/utils/global-utils';
import {
  getApplicantFullName,
  getApplicantFullNameKana,
  getApplicantStaffFullName,
} from '@/utils/applicants';

const contactTypeStatusMap: { [key: number]: { name: string; class: string } } =
  {
    1: {
      name: 'メール',
      class: 'mail',
    },
    2: {
      name: 'LINE',
      class: 'line',
    },
    3: {
      name: 'ブロック中',
      class: 'block',
    },
  };

withDefaults(defineProps<{ isVisible: boolean; applicants: Applicant[] }>(), {
  isVisible: false,
  applicants: () => [],
});

const applicantStaffFullName = (applicant: Applicant) => {
  if (!applicant.staff) return '-';
  return getApplicantStaffFullName(applicant.staff);
};
</script>

<template>
  <div class="table-wrapper custom-table">
    <table class="table">
      <thead>
        <tr>
          <th
            v-for="(col, iCol) in [
              '名前',
              'セイメイ',
              '連絡方法',
              '学校名',
              'メールアドレス',
              '電話番号',
              '登録経路',
              'LINE通知メッセージの送信日時',
              '送信日時',
              '登録日',
              '担当者',
            ]"
            :key="iCol"
          >
            {{ col }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="applicant in applicants" :key="applicant.id">
          <td>{{ getApplicantFullName(applicant) || '-' }}</td>
          <td>
            {{
              getApplicantFullNameKana(
                applicant.firstnameKana || '',
                applicant.lastnameKana || '',
              ) || '-'
            }}
          </td>
          <td
            class="contact-type"
            :class="contactTypeStatusMap[applicant.contactTypeStatus]?.class"
          >
            {{ contactTypeStatusMap[applicant.contactTypeStatus]?.name ?? '-' }}
          </td>
          <td>{{ applicant.school ?? '-' }}</td>
          <td>{{ applicant.email }}</td>
          <td>{{ applicant.tel ?? '-' }}</td>
          <td>{{ applicant.channel ?? '-' }}</td>
          <td>
            {{
              utils.parseDateTime(
                applicant.contactSwitchAnnouncedAt || '',
                'YYYY/MM/DD HH:mm',
              ) || '-'
            }}
          </td>
          <td>
            {{
              utils.parseDateTime(
                applicant.invitedAt || '',
                'YYYY/MM/DD HH:mm',
              ) || '-'
            }}
          </td>
          <td>
            {{
              utils.parseDateTime(
                applicant.activatedAt || '',
                'YYYY/MM/DD HH:mm',
              ) || '-'
            }}
          </td>
          <td>{{ applicantStaffFullName(applicant) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped lang="scss">
@import '@/assets/variables.scss';
@include tableStyle;

.table-wrapper {
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  &.custom-table {
    min-height: initial;
    max-height: 400px;
  }
}
.table {
  overflow: scroll;
  tr {
    &:last-of-type {
      border-bottom: none;
    }
  }
}
.contact-type {
  &.line {
    color: #1dcd00;
    font-weight: bold;
  }
  &.block {
    color: $mochica_color_red;
  }
}
</style>
