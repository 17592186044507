<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import BaseSelect from '@/components/ui/select/BaseSelect.vue';
import SelectionsService from '@/services/selections';
import GraduatedDefines from '@/defines/graduated';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import EmptyContent from '@/components/ui/empty/EmptyContent.vue';

import { useInitialGraduated } from '@/composables/useInitialGraduated';

import type { GraduatedSelection } from '@/types/graduated';

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close']);

const router = useRouter();

const isLoading = ref(false);
const graduatedSelections = ref<GraduatedSelection[]>([]);
const { graduates, selectedGraduateId, initializeGraduatedSelection } =
  useInitialGraduated();

const graduatedYearToLabelName = (year: number) => {
  const res = GraduatedDefines.find(graduated => graduated.year === year);
  if (res !== undefined) return res.name_jp;
  return `${year}年卒`;
};
const handleClickSelection = (selection: GraduatedSelection) => {
  emit('close');
  router.push({
    path: '/selections/registration',
    query: {
      data: encodeURIComponent(
        JSON.stringify({
          notice: selection.notice,
          description: selection.description,
          eventTitle: selection.event.title,
        }),
      ),
    },
  });
};

const fetchGraduatedSelections = async (graduatedId: number) => {
  isLoading.value = true;
  const res = await SelectionsService.fetchGraduatedSelections(graduatedId);
  graduatedSelections.value = res.selections;
  isLoading.value = false;
};

const handleChangeGraduate = async (value: number) => {
  selectedGraduateId.value = value;
  await fetchGraduatedSelections(value);
};

const init = async () => {
  await initializeGraduatedSelection();
  await fetchGraduatedSelections(selectedGraduateId.value);
};

watch(
  () => props.isVisible,
  async newValue => {
    if (newValue) {
      await init();
    }
  },
);
onMounted(async () => {
  await init();
});
</script>
<template>
  <modal-window
    :is-visible="isVisible"
    :max-width="'1040px'"
    title="引用する選考の選択"
    @close="emit('close')"
  >
    <template #content>
      <div class="modal-contents">
        <div class="modal-contents-select">
          <base-select
            :model-value="selectedGraduateId"
            :class="'modal-contents-select'"
            @change="handleChangeGraduate($event.target.value)"
          >
            <option
              v-for="graduate in graduates"
              :key="graduate.id"
              :value="graduate.id"
            >
              {{ graduatedYearToLabelName(graduate.year) }}
            </option>
          </base-select>
        </div>
        <div class="modal-contents-table">
          <div v-if="isLoading" class="loading-wrapper">
            <local-page-loader />
          </div>
          <template v-else>
            <div v-if="graduatedSelections.length > 0" class="table-wrapper">
              <table class="table">
                <thead>
                  <th>選考名</th>
                  <th>管理用メモ</th>
                  <th>応募者向け連絡事項</th>
                </thead>
                <tbody>
                  <tr
                    v-for="selection in graduatedSelections"
                    :key="selection.id"
                    @click="handleClickSelection(selection)"
                  >
                    <td>{{ selection.event.title }}</td>
                    <td class="text-ellipsis">{{ selection.description }}</td>
                    <td class="text-ellipsis">{{ selection.notice }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <empty-content>選考が作成されていません</empty-content>
            </div>
          </template>
        </div>
      </div>
      <div class="modal-contents-bottom">
        <button-base
          :button-text="'キャンセル'"
          button-type="secondary"
          @click="emit('close')"
        />
      </div>
    </template>
  </modal-window>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';

@include tableStyle;

.modal-contents {
  position: relative;
  padding: 20px;

  &-bottom {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 20px;

    button {
      height: 52px;
      width: 264px;
    }
  }
}
.loading-wrapper {
  height: 420px;
}
.modal-contents-select {
  margin: 0 auto;
  max-width: 320px;
}
.table-wrapper {
  overflow-y: scroll;
  height: 420px;
}
.modal-contents-table {
  margin-top: 32px;
}
</style>
