<template>
  <li class="plan_item" :style="{ borderColor: plan.color }">
    <div class="title_block" :style="{ backgroundColor: plan.color }">
      <div class="title">{{ plan.name_ja }}</div>
    </div>
    <div class="content_wrapper">
      <div>
        <p class="fee_title">月額料金</p>
        <p class="count_text" :style="{ color: plan.color }">
          <span v-if="plan.id === planIds.data" class="fee_number">
            {{ planFeeNumbers.data }}
          </span>
          <span v-if="plan.id === planIds.minimum" class="fee_number">
            {{ planFeeNumbers.minimum }}
          </span>
          <span v-if="plan.id === planIds.middle" class="fee_number">
            {{ planFeeNumbers.middle }}
          </span>
          <span v-if="plan.id === planIds.standard" class="fee_number">
            {{ planFeeNumbers.standard }}
          </span>
          円
        </p>
        <div class="applicant_block">
          <p class="content_subtitle">最大応募者数</p>
          <p class="count_text" :style="{ color: plan.color }">
            <span v-if="plan.id === planIds.data" class="applicant_number">
              {{ planApplicantNumbers.data }}
            </span>
            <span v-if="plan.id === planIds.minimum" class="applicant_number">
              {{ planApplicantNumbers.minimum }}
            </span>
            <span v-if="plan.id === planIds.middle" class="applicant_number">
              {{ planApplicantNumbers.middle }}
            </span>
            <span v-if="plan.id === planIds.standard" class="applicant_number">
              {{ planApplicantNumbers.standard }}
            </span>
            <span v-if="plan.id !== planIds.standard" class="applicant_prefix">
              名まで
            </span>
          </p>
        </div>
        <div class="contact_block">
          <button
            type="button"
            class="btn"
            :style="{
              backgroundColor: plan.id === currentPlanId ? '#ccc' : plan.color,
            }"
            :class="{ current: plan.id === currentPlanId }"
            @click="
              plan.id !== currentPlanId
                ? $emit('openModalChangePlan', plan)
                : () => {}
            "
          >
            {{ plan.id === currentPlanId ? '現在利用中' : '申し込み' }}
          </button>
          <p class="attention">
            <span v-if="currentPlanId === 2">
              ※アップグレードできるプランはありません。
            </span>
            <span v-else-if="plan.id === currentPlanId"></span>
            <span v-else>※お申込み後、サポート担当から連絡差し上げます</span>
          </p>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { defineComponent } from 'vue';
import { PLAN_IDS, FEE_NUMBERS, APPLICANT_NUMBERS } from '@/defines/plan';

export default defineComponent({
  name: 'NewPlanItem',
  props: {
    plan: {
      type: Object,
      required: true,
    },
    lightPlanItems: {
      type: Array,
      required: true,
    },
    currentPlanId: {
      type: Number,
      default: null,
    },
  },
  emits: ['openModalChangePlan'],
  setup(props) {
    const isEnabledLightPlan = id => {
      return props.lightPlanItems.map(plan => plan.id).includes(id);
    };
    return { isEnabledLightPlan };
  },
  computed: {
    planIds: () => PLAN_IDS,
    planFeeNumbers: () => FEE_NUMBERS,
    planApplicantNumbers: () => APPLICANT_NUMBERS,
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.plan_item {
  line-height: 1.5;
  border: 4px solid #ccc;
  border-radius: 3px;
}

.title_block {
  width: 100%;
  padding: 20px 12px;
  line-height: 1;
  @media (max-width: ($media_query_sp)) {
    padding: 10px;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    color: $white;
  }
}

.content_wrapper {
  height: 100%;
  font-size: 1.4rem;
  text-align: center;
  white-space: pre-line;
}

.fee_title {
  padding: 30px 12px 10px;
  font-size: $font_14;
  font-weight: bold;
  line-height: 1;
  background-color: $white;
}

.count_text {
  padding: 0 12px 5px;
  font-size: $font_16;
  font-weight: bold;
  line-height: 1;
  background-color: $white;
  .applicant_number,
  .fee_number {
    display: inline-block;
    height: 42px;
    font-size: 42px;
    line-height: 42px;
    vertical-align: 0;
  }
  .applicant_prefix {
    font-weight: bold;
    font-size: $font_16;
    margin-left: 3px;
  }
  .count_wavy_line {
    display: inline-block;
    padding-bottom: 5px;
    height: 42px;
    font-size: $font_20;
    line-height: 42px;
    vertical-align: 8px;
    font-weight: normal;
  }
  .is-annotation {
    font-size: 12px;
    vertical-align: 8px;
    margin-left: -4px;
  }
  .count_light {
    padding-bottom: 15px;
  }
}

.content_subtitle {
  margin-bottom: 10px;
  font-size: $font_14;
  font-weight: bold;
  line-height: 1;
}

.content_value {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 5px;
  font-size: $font_16;
  line-height: 1;
  font-weight: bold;
}

.is-enabled {
  background-image: url('../../../../../assets/img/icon_enabled.svg');
  background-size: auto 16px;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.is-disabled {
  background-image: url('../../../../../assets/img/icon_disabled.svg');
  background-size: auto 16px;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.applicant_block {
  margin-top: 24px;
  padding: 20px 12px;
  border-top: 1px solid #e0e0e0;
}

.contact_block {
  padding-top: 4px;
  background-color: #fff;
  .btn {
    width: 240px;
    max-width: 240px;
    line-height: 2;
    margin-bottom: 16px;
    @media (max-width: (1150px)) {
      width: 90%;
    }
    &:hover {
      opacity: 0.6;
      transition: all ease 0.3s;
    }
    &.current:hover {
      opacity: 1;
      transition: none;
      cursor: auto;
    }
  }
  .attention {
    padding-bottom: 25px;
  }
}

.attention {
  padding: 0 10px;
  font-size: $font_12;
  color: $system_color;
}
</style>
