<template>
  <section>
    <article class="csv-row">
      <div class="csv-download-btn" @click="$emit('downloadCompanyCsv')">
        CSVでダウンロード
      </div>
    </article>
    <article class="admin-count_plan-wrapper">
      <h1>MOCHICA プラン別稼動企業数</h1>
      <div class="admin-count_plan">
        <table class="admin-count_plan__table">
          <thead class="admin-count_plan__thead">
            <tr class="admin-count_plan__thead-tr">
              <td>フリープラン</td>
              <td>ライトプラン</td>
              <td>ライトプラン500</td>
              <td>ライトプラン700</td>
              <td>ライトプラン900</td>
              <td>データプラン</td>
              <td>ミニマムプラン</td>
              <td>ミドルプラン</td>
              <td>スタンダードプラン</td>
            </tr>
          </thead>
          <tbody>
            <tr class="admin-count_plan__tbody-tr">
              <td>{{ companiesCountPlans.free }}</td>
              <td>{{ companiesCountPlans.light }}</td>
              <td>{{ companiesCountPlans.light500 }}</td>
              <td>{{ companiesCountPlans.light700 }}</td>
              <td>{{ companiesCountPlans.light900 }}</td>
              <td>{{ companiesCountPlans.data }}</td>
              <td>{{ companiesCountPlans.minimum }}</td>
              <td>{{ companiesCountPlans.middle }}</td>
              <td>{{ companiesCountPlans.standard }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
    <article class="admin-analytics-wrapper">
      <h1>MOCHICA 全体集計</h1>
      <div class="admin-analytics">
        <table class="admin-analytics__table">
          <thead class="admin-analytics__thead">
            <tr class="admin-analytics__thead-tr row-1">
              <td rowspan="3" class="_edit_ttl">企業合計</td>
              <td rowspan="3" class="_id_ttl">有効アカウント数</td>
              <td rowspan="3" class="_create_ttl" style="display: none">
                作成日
              </td>
              <td rowspan="3" class="_name_ttl" style="display: none">
                会社名
              </td>
              <td rowspan="3" class="_able_ttle" style="display: none">
                アカウント
              </td>
              <td rowspan="3" class="_upload_ttl" style="display: none">
                アップロード
              </td>
              <td rowspan="3" class="_login_ttl" style="display: none">
                直近ログイン日時
              </td>
              <td rowspan="3" class="_near_selection_ttl" style="display: none">
                直近の選考作成日時
              </td>
              <td
                rowspan="3"
                class="_near_notification_ttl"
                style="display: none"
              >
                直近の通知日時
              </td>
              <td rowspan="3" class="_near_tag_ttl" style="display: none">
                直近の応募者フラグ追加日時
              </td>
              <td colspan="3" class="_account_ttl">アカウント発行数</td>
              <td colspan="2" class="_entry_ttl">エントリー数</td>
              <td colspan="4" class="_selection_ttl">選考</td>
              <td class="_notification_ttl">連絡事項</td>
              <td colspan="9" class="_gender_ttl">性別</td>
              <td colspan="2" class="_contact_ttl">連絡手段</td>
              <td colspan="9" class="_category_ttl">文理</td>
              <td colspan="11" class="_status_ttl">ステータス</td>
            </tr>
            <tr class="admin-analytics__thead-tr row-2">
              <td rowspan="2" class="_account_affairs_cnt">人事</td>
              <td rowspan="2" class="_account_person_cnt">担当者</td>
              <td rowspan="2" class="_account_total_cnt">合計</td>
              <td rowspan="2" class="_entry_main_cnt">エントリー</td>
              <td rowspan="2" class="_entry_pre_cnt">登録</td>
              <td rowspan="2" class="_selection_create_cnt">作成数</td>
              <td rowspan="2" class="_selection_recruite_cnt">応募者募集数</td>
              <td rowspan="2" class="_selection_allocation_cnt">
                応募者割当数
              </td>
              <td rowspan="2" class="_selection_reserve_cnt">応募者予約数</td>
              <td rowspan="2" class="_notification_cnt">送信数</td>
              <!-- 性別 -->
              <td colspan="3" class="_gender_main_ttl">エントリー</td>
              <td colspan="3" class="_gender_pre_ttl">登録</td>
              <td colspan="3" class="_gender_total_ttl">総数</td>
              <!-- 連絡手段 -->
              <td rowspan="2" class="_contact_mail_cnt">メール</td>
              <td rowspan="2" class="_contact_line_cnt">LINE</td>
              <!-- 文理 -->
              <td colspan="3" class="_category_main_ttl">エントリー</td>
              <td colspan="3" class="_category_pre_ttl">登録</td>
              <td colspan="3" class="_category_total_ttl">総数</td>
              <!-- ステータス -->
              <td rowspan="2" class="_status_notset_cnt">選考未割当て</td>
              <td rowspan="2" class="_status_notannounce_cnt">未案内</td>
              <td rowspan="2" class="_status_announce_cnt">案内済</td>
              <td rowspan="2" class="_status_notevaluated_cnt">未評価</td>
              <td rowspan="2" class="_status_pending_cnt">人事待ち</td>
              <td rowspan="2" class="_status_pass_cnt">通過</td>
              <td rowspan="2" class="_status_offered_cnt">内定</td>
              <td rowspan="2" class="_status_dropped_cnt">不採用</td>
              <td rowspan="2" class="_status_hired_cnt">採用</td>
              <td rowspan="2" class="_status_canceled_cnt">内定辞退</td>
              <td rowspan="2" class="_status_offercanceled_cnt">辞退</td>
            </tr>
            <tr class="admin-analytics__thead-tr row-3">
              <!-- 性別 -->
              <td class="_gender_main_man_cnt">男性</td>
              <td class="_gender_main_woman_cnt">女性</td>
              <td class="_gender_main_other_cnt">その他</td>
              <td class="_gender_pre_man_cnt">男性</td>
              <td class="_gender_pre_woman_cnt">女性</td>
              <td class="_gender_pre_other_cnt">その他</td>
              <td class="_gender_total_man_cnt">男性</td>
              <td class="_gender_total_woman_cnt">女性</td>
              <td class="_gender_total_other_cnt">その他</td>
              <!-- 文理 -->
              <td class="_category_main_hum_cnt">文</td>
              <td class="_category_main_sci_cnt">理</td>
              <td class="_category_main_other_cnt">その他</td>
              <td class="_category_pre_hum_cnt">文</td>
              <td class="_category_pre_sci_cnt">理</td>
              <td class="_category_pre_other_cnt">その他</td>
              <td class="_category_total_hum_cnt">文</td>
              <td class="_category_total_sci_cnt">理</td>
              <td class="_category_total_other_cnt">その他</td>
            </tr>
          </thead>
          <tbody class="">
            <tr class="admin-analytics__tbody-tr">
              <td class="">{{ companiesTotalDatas.company_count }}</td>
              <td class="">
                {{ companiesTotalDatas.varid_company_count }}
              </td>
              <td class="" style="display: none"></td>
              <td class="" style="display: none"></td>
              <td class="" style="display: none"></td>
              <td class="" style="display: none"></td>
              <td class="" style="display: none"></td>
              <td class="" style="display: none"></td>
              <td class="" style="display: none"></td>
              <!-- アカウント数 -->
              <td class="">{{ companiesTotalDatas.affairs_count }}</td>
              <td class="">{{ companiesTotalDatas.person_count }}</td>
              <td class="">{{ companiesTotalDatas.evaluation_total_count }}</td>
              <!-- エントリー数 -->
              <td class="">{{ companiesTotalDatas.activated_count }}</td>
              <td class="">{{ companiesTotalDatas.non_activated_count }}</td>
              <!-- 選考 -->
              <td class="">{{ companiesTotalDatas.selection_count }}</td>
              <td class="">{{ companiesTotalDatas.recruit_count }}</td>
              <td class="">{{ companiesTotalDatas.assign_count }}</td>
              <td class="">{{ companiesTotalDatas.reserve_count }}</td>
              <!-- 連絡事項 -->
              <td class="">{{ companiesTotalDatas.notice_count }}</td>
              <!-- 性別 -->
              <td class="">
                {{ companiesTotalDatas.gender_activated_male_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.gender_activated_female_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.gender_activated_other_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.gender_non_activated_male_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.gender_non_activated_female_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.gender_non_activated_other_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.gender_total_male_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.gender_total_female_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.gender_total_other_count }}
              </td>
              <!-- 連絡手段 -->
              <td class="">{{ companiesTotalDatas.contact_mail_count }}</td>
              <td class="">{{ companiesTotalDatas.contact_line_count }}</td>
              <!-- 文理 -->
              <td class="">
                {{ companiesTotalDatas.category_activated_male_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.category_activated_female_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.category_activated_other_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.category_non_activated_male_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.category_non_activated_female_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.category_non_activated_other_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.category_total_male_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.category_total_female_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.category_total_other_count }}
              </td>
              <!-- ステータス -->
              <td class="">
                {{ companiesTotalDatas.status_not_set_selection_count }}
              </td>
              <td class="">
                {{ companiesTotalDatas.status_not_announce_count }}
              </td>
              <td class="">{{ companiesTotalDatas.status_announce_count }}</td>
              <td class="">
                {{ companiesTotalDatas.status_not_evaluated_count }}
              </td>
              <td class="">{{ companiesTotalDatas.status_pending_count }}</td>
              <td class="">{{ companiesTotalDatas.status_hired_count }}</td>
              <td class="">{{ companiesTotalDatas.status_offered_count }}</td>
              <td class="">{{ companiesTotalDatas.status_dropped_count }}</td>
              <td class="">{{ companiesTotalDatas.status_canceled_count }}</td>
              <td class="">
                {{ companiesTotalDatas.status_offer_canceled_count }}
              </td>
              <td class="">{{ companiesTotalDatas.status_passed_count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>

    <article class="admin-analytics-wrapper">
      <h1>MOCHICA 会社別集計</h1>
      <div class="admin-analytics">
        <table class="admin-analytics__table">
          <thead class="admin-analytics__thead">
            <tr class="admin-analytics__thead-tr row-1">
              <td rowspan="3" class="_edit_ttl">操作</td>
              <td rowspan="3" class="_id_ttl">会社ID</td>
              <td rowspan="3" class="_create_ttl">作成日</td>
              <td rowspan="3" class="_name_ttl">会社名</td>
              <td rowspan="3" class="_able_ttle">アカウント</td>
              <td rowspan="3" class="_upload_ttl">アップロード</td>
              <td rowspan="3">LINE通知メッセージ</td>
              <td rowspan="3" class="_able_ttle">アカウント納品</td>
              <td rowspan="3" class="_upload_ttl">アカウント納品日</td>
              <td rowspan="3" class="_login_ttl">直近ログイン日時</td>
              <td rowspan="3" class="_near_selection_ttl">
                直近の選考作成日時
              </td>
              <td rowspan="3" class="_near_notification_ttl">直近の通知日時</td>
              <td rowspan="3" class="_near_tag_ttl">
                直近の応募者フラグ追加日時
              </td>
              <td colspan="3" class="_account_ttl">アカウント発行数</td>
              <td colspan="2" class="_entry_ttl">エントリー数</td>
              <td colspan="4" class="_selection_ttl">選考</td>
              <td class="_notification_ttl">連絡事項</td>
              <td colspan="9" class="_gender_ttl">性別</td>
              <td colspan="2" class="_contact_ttl">連絡手段</td>
              <td colspan="9" class="_category_ttl">文理</td>
              <td colspan="11" class="_status_ttl">ステータス</td>
            </tr>
            <tr class="admin-analytics__thead-tr row-2">
              <td rowspan="2" class="_account_affairs_cnt">人事</td>
              <td rowspan="2" class="_account_person_cnt">担当者</td>
              <td rowspan="2" class="_account_total_cnt">合計</td>
              <td rowspan="2" class="_entry_main_cnt">エントリー</td>
              <td rowspan="2" class="_entry_pre_cnt">登録</td>
              <td rowspan="2" class="_selection_create_cnt">作成数</td>
              <td rowspan="2" class="_selection_recruite_cnt">応募者募集数</td>
              <td rowspan="2" class="_selection_allocation_cnt">
                応募者割当数
              </td>
              <td rowspan="2" class="_selection_reserve_cnt">応募者予約数</td>
              <td rowspan="2" class="_notification_cnt">送信数</td>
              <!-- 性別 -->
              <td colspan="3" class="_gender_main_ttl">エントリー</td>
              <td colspan="3" class="_gender_pre_ttl">登録</td>
              <td colspan="3" class="_gender_total_ttl">総数</td>
              <!-- 連絡手段 -->
              <td rowspan="2" class="_contact_mail_cnt">メール</td>
              <td rowspan="2" class="_contact_line_cnt">LINE</td>
              <!-- 文理 -->
              <td colspan="3" class="_category_main_ttl">エントリー</td>
              <td colspan="3" class="_category_pre_ttl">登録</td>
              <td colspan="3" class="_category_total_ttl">総数</td>
              <!-- ステータス -->
              <td rowspan="2" class="_status_notset_cnt">選考未割当て</td>
              <td rowspan="2" class="_status_notannounce_cnt">未案内</td>
              <td rowspan="2" class="_status_announce_cnt">案内済</td>
              <td rowspan="2" class="_status_notevaluated_cnt">未評価</td>
              <td rowspan="2" class="_status_pending_cnt">人事待ち</td>
              <td rowspan="2" class="_status_pass_cnt">通過</td>
              <td rowspan="2" class="_status_offered_cnt">内定</td>
              <td rowspan="2" class="_status_dropped_cnt">不採用</td>
              <td rowspan="2" class="_status_hired_cnt">採用</td>
              <td rowspan="2" class="_status_canceled_cnt">内定辞退</td>
              <td rowspan="2" class="_status_offercanceled_cnt">辞退</td>
            </tr>
            <tr class="admin-analytics__thead-tr row-3">
              <!-- 性別 -->
              <td class="_gender_main_man_cnt">男性</td>
              <td class="_gender_main_woman_cnt">女性</td>
              <td class="_gender_main_other_cnt">その他</td>
              <td class="_gender_pre_man_cnt">男性</td>
              <td class="_gender_pre_woman_cnt">女性</td>
              <td class="_gender_pre_other_cnt">その他</td>
              <td class="_gender_total_man_cnt">男性</td>
              <td class="_gender_total_woman_cnt">女性</td>
              <td class="_gender_total_other_cnt">その他</td>
              <!-- 文理 -->
              <td class="_category_main_hum_cnt">文</td>
              <td class="_category_main_sci_cnt">理</td>
              <td class="_category_main_other_cnt">その他</td>
              <td class="_category_pre_hum_cnt">文</td>
              <td class="_category_pre_sci_cnt">理</td>
              <td class="_category_pre_other_cnt">その他</td>
              <td class="_category_total_hum_cnt">文</td>
              <td class="_category_total_sci_cnt">理</td>
              <td class="_category_total_other_cnt">その他</td>
            </tr>
          </thead>
          <tbody class="">
            <tr
              v-for="companyData in companiesDatas"
              :key="companyData.company_id"
              class="admin-analytics__tbody-tr"
            >
              <td class="">
                <button
                  class="company-edit-btn"
                  @click="$emit('companyIdExitstCheck', companyData.company_id)"
                >
                  編 集
                </button>
              </td>
              <td class="">{{ companyData.company_id }}</td>
              <td class="">
                {{ $utils.parseDateTime(companyData.created_at, 'YYYY/MM/DD') }}
              </td>
              <td class="">{{ companyData.name }}</td>
              <td class="">
                <span
                  class="company-status"
                  :class="{ active: !companyData.deactive }"
                >
                  {{ companyData.deactive ? '無効' : '有効' }}
                </span>
              </td>
              <td class="">
                <span
                  class="company-fileuploadstatus"
                  :class="{ active: companyData.fileupload }"
                >
                  {{ companyData.fileupload ? '有効' : '無効' }}
                </span>
              </td>
              <!-- LINE通知メッセージ -->
              <td>
                <span
                  class="company-line-notification"
                  :class="{ active: companyData.is_line_cooperate_user }"
                >
                  {{ companyData.is_line_cooperate_user ? '有効' : '無効' }}
                </span>
              </td>
              <td class="">
                <span
                  class="company-issuestatus"
                  :class="{ active: companyData.issue }"
                >
                  {{ companyData.issue ? '済み' : '未納' }}
                </span>
              </td>
              <td class="">
                {{ $utils.parseDateTime(companyData.issue_at, 'YYYY/MM/DD') }}
              </td>
              <td class="">
                {{
                  $utils.parseDateTime(
                    companyData.last_login_date,
                    'YYYY/MM/DD HH:mm',
                  )
                }}
              </td>
              <td class="">
                {{
                  $utils.parseDateTime(
                    companyData.last_created_selection_date,
                    'YYYY/MM/DD HH:mm',
                  )
                }}
              </td>
              <td class="">
                {{
                  $utils.parseDateTime(
                    companyData.last_create_notice_date,
                    'YYYY/MM/DD HH:mm',
                  )
                }}
              </td>
              <td class="">
                {{
                  $utils.parseDateTime(
                    companyData.last_update_applicant_tag_date,
                    'YYYY/MM/DD HH:mm',
                  )
                }}
              </td>
              <!-- アカウント数 -->
              <td class="">{{ companyData.affairs_count }}</td>
              <td class="">{{ companyData.person_count }}</td>
              <td class="">{{ companyData.evaluation_total_count }}</td>
              <!-- エントリー数 -->
              <td class="">{{ companyData.activated_count }}</td>
              <td class="">{{ companyData.non_activated_count }}</td>
              <!-- 選考 -->
              <td class="">{{ companyData.selection_count }}</td>
              <td class="">{{ companyData.recruit_count }}</td>
              <td class="">{{ companyData.assign_count }}</td>
              <td class="">{{ companyData.reserve_count }}</td>
              <!-- 連絡事項 -->
              <td class="">{{ companyData.notice_count }}</td>
              <!-- 性別 -->
              <td class="">{{ companyData.gender_activated_male_count }}</td>
              <td class="">{{ companyData.gender_activated_female_count }}</td>
              <td class="">{{ companyData.gender_activated_other_count }}</td>
              <td class="">
                {{ companyData.gender_non_activated_male_count }}
              </td>
              <td class="">
                {{ companyData.gender_non_activated_female_count }}
              </td>
              <td class="">
                {{ companyData.gender_non_activated_other_count }}
              </td>
              <td class="">{{ companyData.gender_total_male_count }}</td>
              <td class="">{{ companyData.gender_total_female_count }}</td>
              <td class="">{{ companyData.gender_total_other_count }}</td>
              <!-- 連絡手段 -->
              <td class="">{{ companyData.contact_mail_count }}</td>
              <td class="">{{ companyData.contact_line_count }}</td>
              <!-- 文理 -->
              <td class="">{{ companyData.category_activated_male_count }}</td>
              <td class="">
                {{ companyData.category_activated_female_count }}
              </td>
              <td class="">{{ companyData.category_activated_other_count }}</td>
              <td class="">
                {{ companyData.category_non_activated_male_count }}
              </td>
              <td class="">
                {{ companyData.category_non_activated_female_count }}
              </td>
              <td class="">
                {{ companyData.category_non_activated_other_count }}
              </td>
              <td class="">{{ companyData.category_total_male_count }}</td>
              <td class="">{{ companyData.category_total_female_count }}</td>
              <td class="">{{ companyData.category_total_other_count }}</td>
              <!-- ステータス -->
              <td class="">{{ companyData.status_not_set_selection_count }}</td>
              <td class="">{{ companyData.status_not_announce_count }}</td>
              <td class="">{{ companyData.status_announce_count }}</td>
              <td class="">{{ companyData.status_not_evaluated_count }}</td>
              <td class="">{{ companyData.status_pending_count }}</td>
              <td class="">{{ companyData.status_hired_count }}</td>
              <td class="">{{ companyData.status_offered_count }}</td>
              <td class="">{{ companyData.status_dropped_count }}</td>
              <td class="">{{ companyData.status_canceled_count }}</td>
              <td class="">{{ companyData.status_offer_canceled_count }}</td>
              <td class="">{{ companyData.status_passed_count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </section>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AdminAnalytics',
  components: {},
  props: {
    companiesCountPlans: {
      type: Object,
      default: () => {},
    },
    companiesDatas: {
      type: Array,
      default: () => [],
    },
    companiesTotalDatas: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['companyIdExitstCheck', 'downloadCompanyCsv'],
  setup(props, context) {
    return {};
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.csv-row {
  width: 100%;
  padding: 30px 30px 0px 30px;
  display: flex;
  justify-content: flex-end;
  .csv-download-btn {
    background-color: #1899d6;
    color: #fff;
    padding: 8px 10px;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    transition: all ease 0.3s;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}

.admin-count_plan-wrapper {
  overflow: auto;
  padding: 30px;
}
.admin-count_plan {
  overflow-x: auto;
}
.admin-count_plan__table {
  background: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.04);
}
.admin-count_plan__thead {
  color: $mochica_color;
}
.admin-count_plan__thead-tr {
}
.admin-count_plan__thead-tr td {
  padding: 8px;
  text-align: left;
  word-break: keep-all;
  background: #eff9ff;
  border: 1px solid #c5c5c5;
}
.admin-count_plan__tbody-tr {
  background: #fff;
  width: 100%;
}
.admin-count_plan__tbody-tr td {
  padding: 8px;
  border: 1px solid #e2e2e2;
  line-height: 1.8;
}

.admin-analytics-wrapper {
  width: 100%;
  overflow: auto;
  padding: 30px;
}
.admin-analytics {
  width: 100%;
  overflow-x: auto;
  background: #fff;
}
.admin-analytics__table {
  background: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.04);
  width: 100%;
}
.admin-analytics__thead {
  color: $mochica_color;
}
.admin-analytics__thead-tr td {
  padding: 8px;
  text-align: left;
  word-break: keep-all;
  background: #eff9ff;
  border: 1px solid #c5c5c5;
}
.admin-analytics__tbody-tr {
  background: #fff;
  width: 100%;
  &:nth-of-type(2n) {
    background: #fdfdfd;
  }
  &:hover {
    background: #f9f9f9;
  }
}
.admin-analytics__tbody-tr td {
  padding: 8px;
  border: 1px solid #e2e2e2;
  line-height: 1.8;
  & + & {
    border-left: 1px solid #f1f1f1;
  }
  &.is-break-word {
    word-wrap: break-word;
    display: block;
  }
}
.admin-analytics__thead-tr td,
.admin-analytics__tbody-tr td {
  &._edit_ttl {
    min-width: 8rem;
    max-width: 8rem;
  }
  &._id_ttl {
  }
  &._create_ttl {
    min-width: 12rem;
    max-width: 12rem;
  }
  &._name_ttl {
    min-width: 20rem;
    max-width: 20rem;
  }
  &._able_ttle {
  }
  &._upload_ttl {
  }
  &._login_ttl {
    min-width: 13rem;
    max-width: 13rem;
  }
  &._near_selection_ttl {
    min-width: 13rem;
    max-width: 13rem;
  }
  &._near_notification_ttl {
    min-width: 13rem;
    max-width: 13rem;
  }
  &._near_tag_ttl {
    min-width: 13rem;
    max-width: 18rem;
  }
  &._account_ttl {
  }
  &._entry_ttl {
  }
  &._selection_ttl {
    text-align: center;
  }
  &._notification_ttl {
  }
  &._gender_ttl {
    text-align: center;
  }
  &._contact_ttl {
    text-align: center;
  }
  &._category_ttl {
    text-align: center;
  }
  &._status_ttl {
  }
  &._account_affairs_cnt {
    min-width: 5rem;
    max-width: rem;
  }
  &._account_person_cnt {
  }
  &._account_total_cnt {
  }
  &._entry_main_cnt {
  }
  &._entry_pre_cnt {
  }
  &._selection_create_cnt {
  }
  &._selection_recruite_cnt {
  }
  &._selection_allocation_cnt {
  }
  &._selection_reserve_cnt {
  }
  &._notification_cnt {
  }
  &._gender_main_ttl {
  }
  &._gender_pre_ttl {
  }
  &._gender_total_ttl {
  }
  &._category_main_ttl {
  }
  &._category_pre_ttl {
  }
  &._category_total_ttl {
  }
  &._status_notset_cnt {
    min-width: 9rem;
    max-width: 9rem;
  }
  &._status_notannounce_cnt {
    min-width: 9rem;
    max-width: 9rem;
  }
  &._status_announce_cnt {
    min-width: 9rem;
    max-width: 9rem;
  }
  &._status_notevaluated_cnt {
    min-width: 9rem;
    max-width: 9rem;
  }
  &._status_pending_cnt {
    min-width: 9rem;
    max-width: 9rem;
  }
  &._status_pass_cnt {
    min-width: 9rem;
    max-width: 9rem;
  }
  &._status_offered_cnt {
    min-width: 9rem;
    max-width: 9rem;
  }
  &._status_dropped_cnt {
    min-width: 9rem;
    max-width: 9rem;
  }
  &._status_hired_cnt {
    min-width: 9rem;
    max-width: 9rem;
  }
  &._status_canceled_cnt {
    min-width: 9rem;
    max-width: 9rem;
  }
  &._status_offercanceled_cnt {
    min-width: 9rem;
    max-width: 9rem;
  }
  &._gender_main_man_cnt {
  }
  &._gender_main_woman_cnt {
  }
  &._gender_main_other_cnt {
  }
  &._gender_pre_man_cnt {
  }
  &._gender_pre_woman_cnt {
  }
  &._gender_pre_other_cnt {
  }
  &._gender_total_man_cnt {
  }
  &._gender_total_woman_cnt {
  }
  &._gender_total_other_cnt {
  }
  &._category_main_hum_cnt {
  }
  &._category_main_sci_cnt {
  }
  &._category_main_other_cnt {
  }
  &._category_pre_hum_cnt {
  }
  &._category_pre_sci_cnt {
  }
  &._category_pre_other_cnt {
  }
  &._category_total_hum_cnt {
  }
  &._category_total_sci_cnt {
  }
  &._category_total_other_cnt {
  }
}

.company-edit-btn {
  background: #ffffff;
  color: #3a8ee6;
  width: 100%;
  padding: 0.4rem;
  border-radius: 2rem;
  cursor: pointer;
  border: 1px solid;
  transition: all ease 0.3s;
  backface-visibility: hidden;
  &:hover {
    background: #3a8ee6;
    color: #ffffff;
  }
}

.company-status,
.company-fileuploadstatus,
.company-issuestatus,
.company-line-notification {
  font-weight: 600;
  border-radius: 30px;
  padding: 4px 18px;
  background-color: $mochica_color_red;
  color: #fff;
  &.active {
    background-color: $mochica_color;
  }
}
</style>
