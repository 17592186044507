<template>
  <div class="home_content">
    <div class="home_header">
      <p class="home_sub_title">未読</p>
      <p class="home_caption">未読のメッセージを確認してください。</p>
    </div>
    <div class="home_counter_group">
      <div class="home_counter">
        <dl
          class="is-unread_messages"
          @click="$router.push({ name: 'ApplicantsUnreadMessages' })"
        >
          <div class="counter_content">
            <dt>未読メッセージ</dt>
            <dd>{{ unreadMessageCount }}</dd>
          </div>
        </dl>
      </div>
      <div class="home_counter">
        <dl
          class="is-unread_flagsurvey"
          @click="$router.push({ name: 'ApplicantsUnreadFlagsurveys' })"
        >
          <div class="counter_content">
            <dt>未読アンケート</dt>
            <dd>{{ unreadFlagsurveyCount }}</dd>
          </div>
        </dl>
      </div>
      <div class="home_counter">
        <dl
          class="is-canceled_notice"
          @click="$router.push({ name: 'ApplicantsCanceledNotices' })"
        >
          <div class="counter_content">
            <dt>キャンセル通知</dt>
            <dd>{{ canceledNoticeCount }}</dd>
          </div>
        </dl>
      </div>
      <div class="home_counter">
        <dl
          class="is-unreserved_applicant"
          @click="$router.push({ name: 'ApplicantsUnreservedApplicants' })"
        >
          <div class="counter_content">
            <dt>未予約者</dt>
            <dd>{{ unreservedApplicantCount }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import applicantService from '@/services/applicant';

export default defineComponent({
  name: 'HomeUnreadMessages',
  setup(props, context) {
    const store = useStore();
    const unreadMessageCount = ref(null);
    const unreadFlagsurveyCount = ref(null);
    const canceledNoticeCount = ref(null);
    const unreservedApplicantCount = ref(null);

    const fetchAll = async () => {
      // 並行処理
      const res = await Promise.all([
        applicantService.getUnreadMessagesSummary(),
        applicantService.getUnreadFlagsurveysSummary(),
        applicantService.getCanceledApplicantsSummary(),
        applicantService.getUnreservedApplicantsSummary(),
      ]);
      if (res[0].success) {
        unreadMessageCount.value = res[0].count_summary;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res[0].message,
          type: false,
        });
      }
      if (res[1].success) {
        unreadFlagsurveyCount.value = res[1].count_summary;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res[1].message,
          type: false,
        });
      }
      if (res[2].success) {
        canceledNoticeCount.value = res[2].count_summary;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res[2].message,
          type: false,
        });
      }
      if (res[3].success) {
        unreservedApplicantCount.value = res[3].count_summary;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res[3].message,
          type: false,
        });
      }
    };

    return {
      unreadMessageCount,
      unreadFlagsurveyCount,
      canceledNoticeCount,
      unreservedApplicantCount,
      fetchAll,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.home_header {
  display: flex;
  margin-bottom: 24px;
}

.home_sub_title {
  font-size: 20px;
  font-weight: bold;
}

.home_caption {
  padding-left: 15px;
  font-size: 14px;
  align-content: center;
  color: #777777;
}

.home_content {
  padding: 30px 30px 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.11);
}

.home_counter_group {
  display: flex;
  justify-content: space-between;
}

.home_counter {
  min-height: 94px;
  min-width: 24.6%;
  padding: 17px 20px;
  margin-bottom: 12px;
  border: 1px solid #d7ebf5;
  border-radius: 4px;
  background: #f3f9fc;
  cursor: pointer;
  > dl {
    line-height: 1;
    display: flex;
    &::before,
    &::after {
      content: '';
      width: 20px;
      height: auto;
      background-position: center;
      background-repeat: no-repeat;
      pointer-events: none;
    }
    &::before {
      background-size: auto 35px;
    }
    &::after {
      background-image: url('../../../../assets/img/arrow_right_blue.svg');
      background-size: auto 20px;
    }
    > div {
      width: 100%;
      > dt {
        position: relative;
        padding-left: 22px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
      > dd {
        padding-left: 22px;
        font-size: 32px;
        font-weight: bold;
        color: #1c91c8;
      }
    }
    &.is-unread_messages {
      &::before {
        width: 50px;
        background-image: url('../../../../assets/img/icon_message.svg');
      }
    }
    &.is-unread_flagsurvey {
      &::before {
        width: 50px;
        background-image: url('../../../../assets/img/icon_clipboard.svg');
      }
    }
    &.is-canceled_notice {
      &::before {
        width: 50px;
        background-image: url('../../../../assets/img/icon_cancel.svg');
      }
    }
    &.is-unreserved_applicant {
      &::before {
        width: 50px;
        background-image: url('../../../../assets/img/icon_calendar_unreserved.svg');
      }
    }
  }
}
</style>
