<template>
  <div class="area-table">
    <table class="table-scrollable">
      <thead class="">
        <tr>
          <th v-for="(column, i) in columns" :key="`column_${i}`">
            <span v-if="column.id === 'is_select'">
              <input
                type="checkbox"
                name="select_head"
                value="1"
                @change="onClickCheckAll"
              />
            </span>
            <span v-else>{{ column.label }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(record, i) in records"
          :key="`record_${i}`"
          :class="{
            'is-clicked':
              hasClickedHighlight === true &&
              clickedList.length > 0 &&
              clickedList[i] === true,
          }"
        >
          <td
            v-for="(column, j) in columns"
            :key="`record_${i}_${j}`"
            :class="column.id"
            @click="openApplicantDetail(record.id, i, column.id)"
          >
            <span v-if="column.id === 'is_select'">
              <input
                type="checkbox"
                name="select"
                value="1"
                :checked="
                  selectedApplicants.find(id => id === record.id) !== undefined
                "
                :disabled="record.isDisabled"
                @change="onClickCheck(record, $event)"
              />
            </span>
            <span v-if="column.id === 'is_send_announce'">
              <button
                type="button"
                class="btn btn-availability"
                @click="showModalSendAnnounce(record)"
              >
                案内を再送する
              </button>
            </span>
            <span
              v-if="column.id === 'status'"
              :class="
                record[column.id].progress.name === null
                  ? 'not_set_selection'
                  : record[column.id].progress.name
              "
              class="status_default"
            >
              {{
                record[column.id].progress.name === null
                  ? '選考未割当て'
                  : record[column.id].progress.description
              }}
            </span>
            <template
              v-else-if="
                column.id === 'contact_type_status' &&
                record.contact_type_status === 'LINE'
              "
            >
              <span class="content_type_status_line">
                {{ record[column.id] }}
              </span>
            </template>
            <template
              v-else-if="
                column.id === 'contact_type_status' &&
                record.contact_type_status === 'ブロック中'
              "
            >
              <span class="content_type_status_line_block">
                {{ record[column.id] }}
              </span>
            </template>
            <template v-else-if="column.id === 'not_announe_lamp'">
              <span
                v-if="
                  record.not_announe_lamp === true &&
                  (record.status.progress.name === 'passed' ||
                    record.status.progress.name === 'offered' ||
                    record.status.progress.name === 'dropped' ||
                    record.status.progress.name === 'hired' ||
                    record.status.progress.name === 'canceled' ||
                    record.status.progress.name === 'offer_canceled')
                "
                class="not_announe_lamp"
              >
                未
              </span>
            </template>
            <template v-else-if="column.id === 'original_filename'">
              <span
                class="file_name"
                :class="{
                  'is-available': canDownloadFile(record.id),
                }"
                @click.stop="downloadFile(record)"
              >
                {{ record[column.id] }}
              </span>
            </template>
            <template v-else>{{ record[column.id] }}</template>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="modal-send-announce">
      <modal-window
        :is-visible="isVisibleSendAnnounceModal"
        :is-loading="isLoading"
        title="案内を再送する"
        :message="'選択した応募者に案内を再送しますか？'"
        :button-text="'再送する'"
        @click="onClickModalSendAnnounce"
        @close="hideModalSendAnnounce"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import uploadService from '@/services/upload';
import NotificationService from '@/services/notifications';

export default defineComponent({
  name: 'ApplicantTable',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    records: {
      type: Array,
      default: () => [],
    },
    hasClickedHighlight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onChangeCurrentSelect'],
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const downloadingUploadIds = ref([]);
    const clickedList = ref(props.records.map(v => false));
    const isVisibleSendAnnounceModal = ref(false);
    const isLoading = ref(false);
    const selectedApplicantSelectionId = ref(null);
    const selectedApplicantIds = ref([]);
    const selectedApplicants = ref([]);

    const openApplicantDetail = (applicantId, index, columnId) => {
      if (columnId === 'is_select' || columnId === 'is_send_announce') return;
      // チェックしたレコードを記録
      clickedList.value = clickedList.value.map((v, i) => {
        if (i === index) return true;
        return v;
      });
      const routeData = router.resolve({
        name: 'ApplicantDetail',
        params: { id: applicantId },
        query: { menu: 0 },
      });
      window.open(routeData.href, '_blank');
    };
    // チェックボックス押下時のイベント
    const onClickCheck = (applicant, ev, checked) => {
      const index = selectedApplicants.value.findIndex(
        applicantId => applicantId === applicant.id,
      );
      selectedApplicantSelectionId.value = applicant.selection_id;
      const newSelected = Object.assign([], selectedApplicants.value);
      if ((ev && ev.target.checked) || (ev === null && checked)) {
        newSelected.push(applicant.id);
      } else if (index !== undefined) {
        newSelected.splice(index, 1);
      }
      selectedApplicants.value = newSelected;
      emitCurrentSelectApplicants();
    };
    // 全選択チェックボックス押下時のイベント
    const onClickCheckAll = ev => {
      let selectedAllApplicantsIds = props.records.map(
        applicant => applicant.id,
      );
      selectedApplicantSelectionId.value = props.records[0].selection_id;
      const currentSelectedApplicantsIds = Object.assign(
        [],
        selectedApplicants.value,
      );
      if (ev.target.checked) {
        selectedApplicants.value = currentSelectedApplicantsIds
          .concat(selectedAllApplicantsIds)
          .filter((x, i, self) => self.indexOf(x) === i);
      } else {
        selectedApplicants.value = currentSelectedApplicantsIds.filter(
          applicantId => selectedAllApplicantsIds.indexOf(applicantId) === -1,
        );
      }
      emitCurrentSelectApplicants();
    };
    const emitCurrentSelectApplicants = () => {
      context.emit('onChangeCurrentSelect', {
        selectedApplicants: selectedApplicants.value,
        selectedApplicantSelectionId: selectedApplicantSelectionId.value,
      });
    };
    const canDownloadFile = uploadId => {
      // PCの画面サイズ、およびファイルダウンロード中でない場合はファイルをダウンロード可能
      return (
        window.innerWidth >= 1025 &&
        downloadingUploadIds.value.find(id => id === uploadId) === undefined
      );
    };
    const downloadFile = async payload => {
      if (canDownloadFile(payload.id) === false) return;
      downloadingUploadIds.value.push(payload.id);
      const res = await uploadService.downloadFile({
        id: payload.upload_id,
        originalFilename: payload.original_filename,
      });
      downloadingUploadIds.value = downloadingUploadIds.value.filter(
        id => id !== payload.upload_id,
      );
      if (!res.success) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.data.message,
          type: false,
        });
      }
    };
    const showModalSendAnnounce = applicant => {
      selectedApplicantSelectionId.value = applicant.selection_id;
      selectedApplicantIds.value.push(applicant.id);
      isVisibleSendAnnounceModal.value = true;
    };
    const onClickModalSendAnnounce = async ({ selected }) => {
      if (selected === 'submit') {
        isLoading.value = true;
        await sendAnnounce();
        isLoading.value = false;
      }
      hideModalSendAnnounce();
      return;
    };
    const sendAnnounce = async () => {
      const response = await NotificationService.venuesCheck(
        selectedApplicantSelectionId.value,
        selectedApplicantIds.value,
      );
      if (response.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: response.message,
          type: response.success,
        });
        return;
      }
      const res = await NotificationService.sendVenues(
        selectedApplicantSelectionId.value,
        selectedApplicantIds.value,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    const hideModalSendAnnounce = () => {
      selectedApplicantSelectionId.value = null;
      selectedApplicantIds.value = [];
      isVisibleSendAnnounceModal.value = false;
    };

    return {
      downloadingUploadIds,
      clickedList,
      isVisibleSendAnnounceModal,
      isLoading,
      selectedApplicantSelectionId,
      selectedApplicantIds,
      selectedApplicants,
      openApplicantDetail,
      onClickCheck,
      onClickCheckAll,
      canDownloadFile,
      downloadFile,
      showModalSendAnnounce,
      onClickModalSendAnnounce,
      sendAnnounce,
      hideModalSendAnnounce,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.area-table {
  max-height: 800px;
  overflow-x: auto;
  > table {
    width: 100%;
    > thead {
      > tr {
        background-color: $mochica_color_gray2;
        > th {
          position: sticky;
          z-index: 12;
          top: 0;
          background-color: $mochica_color_gray2;
          padding: 0 1.5rem;
          height: 3.6rem;
          text-align: left;
          border-collapse: collapse;
          white-space: nowrap;
          input[type='checkbox'] {
            appearance: none;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 0.4rem;
            border: 2px solid $mochica_color_gray;
            background: $white;
            position: relative;
            transform: translate3d(0px, 0px, 0px);
            cursor: pointer;
            &:checked {
              &::after {
                content: url(../../../../assets/img/check.svg);
                height: 1.1rem;
                width: 1.5rem;
                margin: -5px 0 0 1px;
                position: absolute;
                bottom: 3px;
                left: 0;
              }
            }
          }
        }
      }
    }
    > tbody {
      background: white;
      white-space: nowrap;
      > tr {
        height: 4.4rem;
        border: none;
        border-bottom: 1px solid $mochica_color_gray2;
        transition: all ease 0.1s;
        cursor: pointer;
        &:hover {
          background-color: #f1f1f1;
        }
        &.is-clicked {
          > td {
            background-color: #ededed;
          }
        }
        > td {
          padding: 0 1.5rem;
          border-collapse: collapse;
          background: $white;
          z-index: 10;
          input[type='checkbox'] {
            appearance: none;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 0.4rem;
            border: 2px solid $mochica_color_gray;
            background: $white;
            position: relative;
            transform: translate3d(0px, 0px, 0px);
            cursor: pointer;
            &:checked {
              &::after {
                content: url(../../../../assets/img/check.svg);
                height: 1.1rem;
                width: 1.5rem;
                margin: -5px 0 0 1px;
                position: absolute;
                bottom: 3px;
                left: 0;
              }
            }
          }
          &.unread_line_count,
          &.unread_mail_count {
            text-align: center;
            color: #e83d86;
            &::after {
              display: inline-block;
              content: '件';
              vertical-align: 0;
            }
          }
          .content_type_status_line {
            color: #1dcd00;
            font-weight: bold;
          }
          .content_type_status_line_block {
            color: $mochica_color_red;
          }
          .status_default {
            display: inline-block;
            width: 9rem;
            height: 28px;
            line-height: 27px;
            text-align: center;
            border-radius: 83px;
            color: $white;
            font-weight: bold;
          }
          .not_set_selection {
            background-color: $not_set_selection_bg;
          }
          .not_announced {
            background-color: $not_announced_bg;
          }
          .announced {
            color: $announced_color;
          }
          .not_evaluated {
            background-color: $not_evaluated_bg;
          }
          .pending {
            background-color: $pending_bg;
          }
          .finished {
            border-color: $finished_border;
            background-color: $finished_bg;
          }
          .hired {
            color: $hired_color;
          }
          .offered {
            color: $offered_color;
          }
          .dropped {
            color: $dropped_color;
          }
          .canceled {
            color: $canceled_color;
          }
          .offer_canceled {
            color: $offer_canceled_color;
          }
          .passed {
            background-color: $passed_bg;
          }
        }
      }
    }
  }
  .table-scrollable {
    text-align: left;
    > thead {
      tr {
        th {
          table-layout: fixed;
          height: 3.6rem;
          padding-left: 1rem;
        }
      }
    }
    > tbody {
      overflow-y: scroll;
      // height: 400px;
      tr {
        td {
          table-layout: fixed;
          height: 3.6rem;
          padding-left: 1rem;
        }
      }
    }
  }
}

.file_name {
  &.is-available {
    color: #16b2d9;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.not_announe_lamp {
  color: #e83d86;
  text-align: center;
}

@media (max-width: ($media_query_tablet)) {
  .file_name {
    &.is-available {
      color: #333;
      cursor: default;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: ($media_query_sp)) {
  .area-table table {
    font-size: 1.1rem;
    thead tr th {
      padding: 0 5px;
      &:first-of-type {
        padding: 0 5px 0 10px;
      }
    }
    tbody tr td {
      padding: 0 5px;
      &:first-of-type {
        padding: 0 5px 0 10px;
      }
    }
    tbody tr td .status_default {
      width: 100%;
      height: auto;
      padding: 0 5px;
      font-size: 0.9rem;
    }
  }
}
</style>
