<template>
  <div class="content-warp">
    <div>
      <div v-if="isLoadPage === true" class="no-lists-contents">
        <local-page-loader v-show="isLoadPage" />
      </div>
      <template v-else>
        <div v-show="!isLoadPage" class="header-content">
          <p v-if="count > 0">
            {{ title }}が
            <span class="unread_count_font">
              <span>{{ count }}</span>
              件
            </span>
            あります
          </p>
          <p v-else class="result_message">{{ resultMessage }}</p>
          <div class="reset_button">
            <div data-v-21fbb7e0 class="btn btn-availability" @click="reload()">
              {{ title }}を更新
            </div>
          </div>
        </div>
        <template v-if="count > 0">
          <div
            v-for="(unreadGraduated, i) in targetGraduateds"
            :key="`listHead_${i}`"
            class="list-wrapper"
            :class="{ 'is-open': unreadGraduated.isOpen }"
          >
            <div
              class="list-head"
              @click="setApplicant(i, unreadGraduated.graduated)"
            >
              {{ unreadGraduated.title }}
              <img
                class="arrow_icon"
                src="@/assets/img/arrow_right_white.svg"
                alt=""
              />
            </div>
            <div v-if="unreadGraduated.isOpen" class="list-table">
              <local-page-loader
                v-if="unreadGraduated.isLoading"
                class="loader"
              />
              <div
                v-if="isVisibleSendBulkAnnounce"
                class="bulk-send-announce-button"
              >
                <div
                  class="btn btn-availability"
                  @click="showModalSendBulkAnnounce"
                >
                  選択した応募者に一括で案内を再送する
                </div>
              </div>
              <applicant-table
                v-if="recordsByGraduated[i].length > 0"
                :columns="columns"
                :records="recordsByGraduated[i]"
                :has-clicked-highlight="true"
                @onChangeCurrentSelect="updateSelectedApplicants"
              />
            </div>
          </div>
        </template>
      </template>
    </div>

    <div class="modal-send-bulk-announce">
      <modal-window
        :is-visible="isVisibleSendBulkAnnounceModal"
        :is-loading="isLoading"
        title="案内を再送する"
        :message="'選択した応募者に案内を再送しますか？'"
        :button-text="'再送する'"
        @click="onClickModalSendBulkAnnounce"
        @close="hideModalSendBulkAnnounce"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';
import ApplicantTable from '@/components/features/applicantTable/components/ApplicantTable';
import cloneDeep from 'lodash.clonedeep';
import NotificationService from '@/services/notifications';

export default defineComponent({
  name: 'ApplicantsUnreadPageContent',
  components: { LocalPageLoader, ApplicantTable },
  props: {
    title: {
      type: String,
      required: true,
    },
    additionalHeader: {
      type: Array,
      default: () => [],
    },
    invisibleHeader: {
      type: Array,
      default: () => [],
    },
    initMethod: {
      type: Function,
      required: true,
    },
    fetchMethod: {
      type: Function,
      required: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    isVisibleSendBulkAnnounce: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const store = useStore();
    const count = ref(0);
    const resultMessage = ref('');
    const targetGraduateds = ref([]);
    const recordsByGraduated = ref([]);
    const isLoadPage = ref(false);
    const isVisibleSendBulkAnnounceModal = ref(false);
    const selectedApplicantSelectionId = ref(null);
    const isLoading = ref(false);
    const selectedApplicantIds = ref([]);

    // methods
    const init = async () => {
      isLoadPage.value = true;
      const res = await props.initMethod();
      isLoadPage.value = false;
      if (res.success !== true) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: res.data.message,
          type: false,
        });
        return;
      }
      count.value = res.count_summary;
      targetGraduateds.value = res.graduateds;
      recordsByGraduated.value = res.graduateds.map(() => []);
      resultMessage.value = res.message;
    };
    const reload = async () => {
      targetGraduateds.value = [];
      recordsByGraduated.value = [];
      await init();
    };
    const setApplicant = async (index, graduatedYear) => {
      if (targetGraduateds.value[index].isOpen === undefined) {
        if (targetGraduateds.value[index].isLoading) return;
        if (
          recordsByGraduated.value.length > 0 &&
          recordsByGraduated.value[index].length > 0
        ) {
          // 既にfetch済みの場合なにもしない
          return;
        }
        targetGraduateds.value = targetGraduateds.value.map((v, i) => {
          if (index === i) return { ...v, isOpen: true, isLoading: true };
          return v;
        });
        const res = await props.fetchMethod(graduatedYear);
        targetGraduateds.value = targetGraduateds.value.map((v, i) => {
          if (index === i) return { ...v, isLoading: false };
          return v;
        });
        if (res.success !== true) {
          store.dispatch('notification/VISIBLE_NOTIFICATION', {
            message: res.data.message,
            type: false,
          });
          targetGraduateds.value = targetGraduateds.value.map((v, i) => {
            if (index === i) return { ...v, isOpen: true };
            return v;
          });
          return;
        }
        recordsByGraduated.value[index] = res.list;
      } else if (targetGraduateds.value[index].isOpen) {
        targetGraduateds.value = targetGraduateds.value.map((v, i) => {
          if (index === i) return { ...v, isOpen: false };
          return v;
        });
      } else {
        targetGraduateds.value = targetGraduateds.value.map((v, i) => {
          if (index === i) return { ...v, isOpen: true };
          return v;
        });
      }
    };
    const updateSelectedApplicants = async payload => {
      if (payload) {
        selectedApplicantIds.value = cloneDeep(payload.selectedApplicants);
        selectedApplicantSelectionId.value = cloneDeep(
          payload.selectedApplicantSelectionId,
        );
      }
    };
    const showModalSendBulkAnnounce = applicant => {
      if (selectedApplicantIds.value.length === 0) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: '応募者を選択してください',
          type: false,
        });
        return;
      }
      isVisibleSendBulkAnnounceModal.value = true;
    };
    const onClickModalSendBulkAnnounce = async ({ selected }) => {
      if (selected === 'submit') {
        isLoading.value = true;
        await sendBulkAnnounce();
        isLoading.value = false;
      }
      hideModalSendBulkAnnounce();
      return;
    };
    const sendBulkAnnounce = async () => {
      const response = await NotificationService.venuesCheck(
        selectedApplicantSelectionId.value,
        selectedApplicantIds.value,
      );
      if (!response.success) {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: response.message,
          type: response.success,
        });
        return;
      }
      const res = await NotificationService.sendVenues(
        selectedApplicantSelectionId.value,
        selectedApplicantIds.value,
      );
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
    };
    const hideModalSendBulkAnnounce = () => {
      isVisibleSendBulkAnnounceModal.value = false;
    };

    // lifecycle
    onBeforeMount(async () => {
      await init();
    });

    return {
      count,
      resultMessage,
      targetGraduateds,
      recordsByGraduated,
      isLoadPage,
      isVisibleSendBulkAnnounceModal,
      selectedApplicantIds,
      isLoading,
      reload,
      setApplicant,
      updateSelectedApplicants,
      showModalSendBulkAnnounce,
      onClickModalSendBulkAnnounce,
      sendBulkAnnounce,
      hideModalSendBulkAnnounce,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.content-warp {
  padding-top: 0;
  > div {
    padding: 22px 30px;
    background-color: #fff;
  }
}

.result_message {
  line-height: 34px;
}

.list-wrapper {
  position: relative;
  margin-bottom: 10px;
  background-color: #fff;

  > .list-head {
    cursor: pointer;
    position: relative;
    padding: 20px;
    font-size: 1.4rem;
    font-weight: bold;
    color: #fff;
    background-color: #1698d9;

    > span {
      font-weight: bold;
    }
    > .arrow_icon {
      position: absolute;
      right: 20px;
      top: 20px;
      height: 16px;
      transform: rotate(90deg);
      transition: transform 0.3s ease;
    }
  }

  > .list-table {
    padding: 20px;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;

    > .loader {
      position: absolute;
      height: 97px;
      min-height: 97px;
      max-height: 97px;
    }
  }

  &.is-open {
    > .list-head {
      > .arrow_icon {
        transform: rotate(-90deg);
      }
    }
    > .list-table {
      min-height: 137px;
    }
  }
}

.reset_button {
  width: 100%;
  text-align: right;
  > .btn {
    display: inline-block;
  }
}

.bulk-send-announce-button {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  > .btn {
    display: inline-block;
  }
}

.header-content {
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 1.4rem;
  border-radius: 4px;
  white-space: nowrap;
  background: #f5f5f5;
  > p {
    font-weight: bold;
  }
}

.unread_count_font {
  margin-left: 2px;
  color: #e83d85;
  > span {
    font-size: 28px;
  }
}

.no-lists-contents {
  height: 150px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.area-table) {
  width: 100%;
}

@media (max-width: ($media_query_sp)) {
  .header-content {
    display: block;
  }

  .reset_button {
    > .btn {
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
