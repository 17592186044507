<template>
  <div @click="refHomeEvent ? refHomeEvent.closeCalendarDetail() : () => {}">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">ホーム</span>
      </template>
    </bread-crumb-list>

    <local-page-loader v-if="isLoading === true" />
    <section class="content-warp">
      <a href="https://help.mochica.jp/mochica-new-plan2025" target="_blank">
        <img
          v-if="!isNewPlan"
          class="new-plan-banner"
          src="@/assets/img/new_plan_banner.svg"
          alt="new_plan_banner"
        />
      </a>
      <home-unread-messages ref="refHomeUnreadMessages" />
      <home-latest ref="refHomeLatest" />
      <div class="flex_box">
        <div>
          <home-news ref="refHomeNews" />
        </div>
        <home-event ref="refHomeEvent" />
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';

import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import HomeNews from '@/components/page/home/components/HomeNews';
import HomeUnreadMessages from '@/components/page/home/components/HomeUnreadMessages';
import HomeLatest from '@/components/page/home/components/HomeLatest';
import HomeEvent from '@/components/page/home/components/HomeEvent';
import { getIsNewPlanById } from '@/defines/plan';

export default defineComponent({
  name: 'Home',
  components: {
    LocalPageLoader,
    BreadCrumbList,
    HomeNews,
    HomeUnreadMessages,
    HomeLatest,
    HomeEvent,
  },
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(true);
    const refHomeNews = ref(null);
    const refHomeUnreadMessages = ref(null);
    const refHomeLatest = ref(null);
    const refHomeEvent = ref(null);

    onMounted(async () => {
      await store.dispatch('page/SET_LOADED');
      isLoading.value = true;
      // 複数APIを叩くので並列処理して同時リクエスト
      try {
        await Promise.all([
          refHomeNews.value.fetchAll(),
          refHomeUnreadMessages.value.fetchAll(),
          refHomeLatest.value.fetchAll(),
        ]);
      } catch (e) {
        console.error(e);
      }
      isLoading.value = false;
    });

    const plan = computed(() => store.getters['plan/plan']);
    const isNewPlan = computed(() => getIsNewPlanById(plan.value.id));

    return {
      isLoading,
      refHomeNews,
      refHomeUnreadMessages,
      refHomeLatest,
      refHomeEvent,
      isNewPlan,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.localpageloader {
  position: absolute;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
}

.flex_box {
  display: flex;
  > div {
    &:nth-child(1) {
      width: 100%;
      margin-right: 10px;
    }
    &:nth-child(2) {
      width: 100%;
      margin-left: 10px;
    }
  }
}

.new-plan-banner {
  margin-bottom: 20px;
}

@media (max-width: (700px)) {
  .flex_box {
    display: block;
    > div {
      &:nth-child(1) {
        max-width: 100%;
        margin-right: 0;
      }
      &:nth-child(2) {
        margin-left: 0;
      }
    }
  }
}
</style>
