import { ref } from 'vue';
import { useStore } from 'vuex';
import { GRADUATED_OTHERS_ID_THRESHOLD } from '@/defines/graduate';
import type { Graduated } from '@/types/graduated';

export const useInitialGraduated = () => {
  const store = useStore();

  const graduates = ref<Graduated[]>([]);
  const selectedGraduateId = ref<number>(0);

  const initializeGraduatedSelection = async () => {
    graduates.value = store.getters['graduateds/graduateds'];
    const storeGraduatedYear =
      await store.getters['graduateds/selectedGraduatedYear'];

    // 前年度の卒業年度を取得
    if (storeGraduatedYear.year < GRADUATED_OTHERS_ID_THRESHOLD) {
      const lastYearGraduated = graduates.value.find(
        graduated => graduated.year === storeGraduatedYear.year - 1,
      );

      if (lastYearGraduated) {
        selectedGraduateId.value = lastYearGraduated.id;
        return;
      }
    }
    if (graduates.value.length === 0) return;

    // 選択中の卒年がアルバイトなどの場合、最新の卒業年度を取得
    if (storeGraduatedYear.year >= GRADUATED_OTHERS_ID_THRESHOLD) {
      const latestGraduated = graduates.value.reduce((max, current) => {
        if (current.year <= GRADUATED_OTHERS_ID_THRESHOLD) {
          return current.year > max.year ? current : max;
        }
        return max;
      }, graduates.value[0]);
      selectedGraduateId.value = latestGraduated.id;
    } else {
      selectedGraduateId.value = storeGraduatedYear.id;
    }
  };

  return {
    graduates,
    selectedGraduateId,
    initializeGraduatedSelection,
  };
};
