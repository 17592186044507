<template>
  <section class="as-block" :class="{ 'is-preview': isPreview === true }">
    <div class="headline">
      <img src="@/assets/img/icon_flagsurvey.svg" />
      アンケート
    </div>

    <div class="inner">
      <drop-down
        v-if="isPreview !== true"
        class="selectFlagSurvey"
        placeholder="アンケートを選択してください"
        :selected-id="selectedFlagSurveyNoticeId"
        :list="flagsurveyNotices"
        :is-view="isOpenSelectFlagSurvey"
        @onOpen="isOpenSelectFlagSurvey = true"
        @onClose="isOpenSelectFlagSurvey = false"
        @onSelected="onSelectedFlagSurvey($event.item.id)"
      />
      <ul v-else class="questions">
        <li>
          <dl>
            <dt>アンケートタイトル</dt>
            <dd>
              {{ flagsurveyNotice.title }}
            </dd>
          </dl>
        </li>
      </ul>
      <template v-if="selectedFlagSurveyNoticeId !== 0 || isPreview">
        <p
          v-if="
            flagsurveyNotice.disable === true &&
            flagsurveyNotice.answered_at === null
          "
          class="not_answered_flagsurvey"
        >
          ※このアンケートは回答前に変更されたため、無効になりました。
        </p>
        <p
          v-else-if="
            (flagsurveyNotice.questions &&
              !flagsurveyNotice.questions[0].answers) ||
            (flagsurveyNotice.questions &&
              flagsurveyNotice.questions[0].answers.length === 0)
          "
          class="not_answered_flagsurvey"
        >
          ※未回答のアンケートです。
        </p>
        <ul v-else class="questions">
          <li v-for="q in flagsurveyNotice.questions" :key="`question_${q.id}`">
            <dl>
              <dt>{{ q.question_title }}</dt>
              <dd>
                <template v-if="q.question_type === 1">
                  <ul>
                    <li v-for="(answer, i) in q.answers" :key="`answer_${i}`">
                      {{ answer }}
                    </li>
                  </ul>
                </template>
                <p v-else-if="q.question_type === 2">
                  {{ q.answers[0] }}
                </p>
              </dd>
            </dl>
          </li>
        </ul>
      </template>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref } from 'vue';

import DropDown from '@/components/ui/menus/components/DropDown';
import flagsurveyNoticeService from '@/services/flag-survey-notice';

export default defineComponent({
  name: 'ApplicantFlagSurveys',
  components: { DropDown },
  props: {
    flagsurveyNotices: {
      type: Array,
      required: true,
    },
    flagsurveyNotice: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateFlagSurvey'],
  setup(props, context) {
    const selectedFlagSurveyNoticeId = ref(0);
    const isOpenSelectFlagSurvey = ref(false);

    // methods
    const onSelectedFlagSurvey = async id => {
      if (props.isLoading === true) return;
      selectedFlagSurveyNoticeId.value = id;
      if (id === 0) {
        context.emit('updateFlagSurvey', id);
        return;
      }
      const res = await flagsurveyNoticeService.updateAnswerRead({
        flagsurvey_notice_id: id,
      });
      if (res.success) context.emit('updateFlagSurvey', id);
    };

    return {
      selectedFlagSurveyNoticeId,
      isOpenSelectFlagSurvey,
      onSelectedFlagSurvey,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.as-block {
  margin-bottom: 20px;
  background-color: #fff;
}

.headline {
  padding: 10px 20px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: #4397d1;
  > img {
    margin-right: 10px;
    width: auto;
    height: 20px;
    vertical-align: -4px;
  }
}

.is-preview {
  margin-top: 20px;
  .headline {
    padding: 14px;
    font-size: 1.3rem;
    border-radius: 4px;
    text-align: center;
    > img {
      display: none;
    }
  }
  .inner {
    padding: 10px 0 0;
  }
}

.inner {
  position: relative;
  padding: 30px 20px 10px 20px;
  .file_upload_btn {
    margin-bottom: 20px;
  }
}

.selectFlagSurvey {
  width: 100%;
  margin-bottom: 20px;
}

.not_answered_flagsurvey {
  margin-bottom: 20px;
  color: $mochica_color_red;
}

.questions {
  > li {
    > dl {
      line-height: 1.5;
      margin-bottom: 30px;
      > dt {
        padding: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        background-color: #d0d0d0;
      }
      > dd {
        margin-bottom: 10px;
        word-break: break-all;
        word-wrap: break-word;
        > p {
          margin-bottom: 20px;
          white-space: pre-line;
        }
        > ul {
          display: flex;
          flex-wrap: wrap;
          > li {
            padding: 10px;
            margin: 0 10px 10px 0;
            line-height: 1;
            border-radius: 4px;
            background-color: #ebebeb;
            word-break: break-all;
          }
        }
      }
    }
  }
}
</style>
