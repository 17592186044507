import { Applicant } from '@/types/applicant';

export const getApplicantFullName = (applicant: Applicant): string =>
  `${applicant.lastname ?? ''}${applicant.firstname ?? ''}`;

export const getApplicantFullNameKana = (
  firstname_kana: string,
  lastname_kana: string,
): string => `${lastname_kana ?? ''}${firstname_kana ?? ''}`;

export const getDepartmentCategoryDescription = (
  departmentCategory: Exclude<Applicant['departmentCategory'], null>,
): string =>
  departmentCategory === null ? 'その他' : departmentCategory.description;

export const getApplicantStaffFullName = (
  staff: Exclude<Applicant['staff'], null>,
): string => `${staff.lastname}${staff.firstname}`;
