import httpClient from '@/http-client';
import Selection from '@/models/selection';

const SelectionsManager = function _SelectionsManager() {
  const manager = Object.create(SelectionsManager.prototype);
  return manager;
};

SelectionsManager.prototype = {
  async fetchSelection(id, sort = 1) {
    const res = await httpClient.get(`/selections/${id}/?venue_sort=${sort}`);
    if (res.status === 200) {
      return {
        isConnectedGoogleCalendar: res.data.is_connected_google_calendar,
        isConnectedGoogleWorkspaceCustomerId:
          res.data.is_connected_google_workspace_customer_id,
        calendarResources: res.data.calendar_resources,
        selection: Selection.createFromJSON(res.data.selection),
        enableZoom: res.data.enable_zoom,
        zoomUsers: res.data.zoom_users,
      };
    }
    return null;
  },
  async fetchSelectionSortDate(selectionId, sort = 1) {
    const response = await httpClient.get(
      `/selections/${selectionId}/?venue_sort=${sort}`,
    );
    return Selection.createFromJSON(response.data.selection);
  },
  async createSelection(selection) {
    const res = await httpClient.post('/selections', selection);
    return res.data;
  },
  async deleteSelection(id) {
    const result = await httpClient.delete(`/selections/${id}`);
    return result.data;
  },
  async updateSelection(
    id,
    eventId,
    notice,
    description,
    reserve,
    updateFlag,
    reminderCheck,
  ) {
    const result = await httpClient.put(`/selections/${id}`, {
      selection: {
        event_id: eventId,
        notice,
        description,
        reserve,
        reminder_check: reminderCheck,
        update_flag: updateFlag,
      },
    });
    return result.data;
  },
  async selectionReserveToggle(id, payload) {
    const result = await httpClient.post(
      `/selections/${id}/reserve_toggle`,
      payload,
    );
    return result.data;
  },

  // 会場操作
  async createVenue(selectionId, venue) {
    const res = await httpClient.post(`/selections/${selectionId}/venues`, {
      venue,
    });
    return res.data;
  },
  async deleteVenue(selectionId, venueId) {
    const result = await httpClient.delete(
      `/selections/${selectionId}/venues/${venueId}`,
    );
    return result.data;
  },
  async updateVenue(selectionId, venueId, venueData, modeLimitDate = false) {
    const res = await httpClient.put(
      `/selections/${selectionId}/venues/${venueId}`,
      { venue: venueData, mode_limit_date: modeLimitDate },
    );
    return res.data;
  },
  async updateActiveVenue(selectionId, venueId, venueData) {
    const result = await httpClient.put(
      `/selections/${selectionId}/venues/${venueId}/active`,
      { venue: venueData },
    );
    return result.data;
  },

  // 開催時間操作
  async updateTimetables(selectionId, venueId, timetables) {
    const result = await httpClient.post(
      `/selections/${selectionId}/venues/${venueId}/timetables`,
      { timetables },
    );
    return result.data;
  },
  async deleteTimetable(selectionId, venueId, timetableId) {
    const result = await httpClient.delete(
      `/selections/${selectionId}/venues/${venueId}/timetables/${timetableId}`,
    );
    return result.data;
  },
  async updateGoogleEvent(selectionId, venueId, timetableId) {
    const result = await httpClient.post(
      `/selections/${selectionId}/venues/${venueId}/timetables/${timetableId}/update_google_event`,
    );
    return result.data;
  },
  async updateZoomMeeting(selectionId, venueId, timetableId) {
    const result = await httpClient.put(
      `/selections/${selectionId}/venues/${venueId}/timetables/${timetableId}/zoom_meeting`,
    );
    return result.data;
  },
  async deleteZoomMeeting(selectionId, venueId, timetableId) {
    const result = await httpClient.delete(
      `/selections/${selectionId}/venues/${venueId}/timetables/${timetableId}/zoom_meeting`,
    );
    return result.data;
  },
  async sendAssignmentReminder(selectionId, venueId, timetableId) {
    const result = await httpClient.post(
      `/selections/${selectionId}/venues/${venueId}/timetables/${timetableId}/send_assignment_reminder`,
    );
    return result.data;
  },
  async fetchGraduatedSelections(graduatedId) {
    const result = await httpClient.get(`/selections/graduated`, {
      params: {
        graduated_id: graduatedId,
      },
    });
    return result.data;
  },
};

export default SelectionsManager();
